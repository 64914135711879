import { useApolloClient } from '@apollo/client';
import Formsy, { addValidationRule } from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import {
    AgentEntryType,
    CHECK_ASSIGN_USER,
    FETCH_TYPE,
    IS_USER_ACCESS_PAGE,
    LEAD_STATUS,
    MAX_AGENT_LIMIT,
    STATUS_CODES
} from '../../../config/constants';
import { encode } from '../../../config/crypto';
import { AGENT_LIST_FOR_STA } from '../../../services/agent.ggl';
import { LTO_AGENT_SERVICE_CHARGE, SEND_TO_AGENT } from '../../../services/leads.gql';
import { getLoanDetail } from '../../../store/action/allAction';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import Modal from '../../elements/Modal';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import DocumentGallery from '../DocumentGallery';
import { SEND_TO_AGENT as FORM_FIELDS } from '../formFields/sendToAgentFormFields.json';
import AgentServiceTatViewAction from './AgentServiceTatViewAction';

// const FORM_FIELDS = FormFields.SEND_TO_AGENT;
const SendToAgentForm = (props) => {
    addValidationRule('isNegativeValue', function (values, value) {
        if (value < 0) {
            return "Negative values are not allowed";
        }
        return true;
    });

    const [isReadOnly, setIsReadOnly] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [AgentListPopup, setAgentListPopup] = useState(false);
    const [confirmSelectAgents, setConfirmSelectAgents] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [sendToAgentInput, setSendToAgentInput] = useState({});
    const [agentList, setAgentList] = useState([]);
    const [allAgentList, setAllAgentList] = useState({ mapped: [], unmapped: [] });
    const [searchText, setSearchText] = useState("");
    const [statusTrackingUrl, setStatusTrackingUrl] = useState('');
    const { masterdata, serviceKeyValue, lead, remarksHistory, lead_status_history } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadFiles: lead && lead.leadDetail && (lead.leadDetail.leaddocs || []),
        lead_status_history: lead?.leadDetail?.lead_status_history || [],
        serviceKeyValue: masterdata?.data?.service_type?.reduce((result, item) => {
            result[item?.id] = { standard_price: item?.standard_pirce };
            return result;
        }, {})
    }));

    const client = useApolloClient();
    const dispatch = useDispatch();

    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'send-to-agent')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo?.id || 0;
    let role_id = UserInfo?.role_id?.[0] || 0;

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'send-to-agent').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);

    let isAgentSelected = Object.keys(sendToAgentInput?.lead_agents || {})?.length > 0 ? false : true;
    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];

    useEffect(() => {
        const fetchData = async () => {
            await getSentToAgentData();
        };
        fetchData();
    }, [lead?.leadDetail]);

    const getSentToAgentData = async () => {
        await ltoAgentList(lead?.leadDetail?.lead_detail?.inbound_lt_office_id, lead.leadDetail?.service_type_id);
        let updatedValues = {};
        if (lead?.leadDetail && (!sendToAgentInput['service_type_id'] || !sendToAgentInput['agent_id'])) {
            updatedValues.service_type_id = lead.leadDetail?.service_type_id || 0;
            updatedValues.agent_id = lead.leadDetail?.agent_id || 0;
        }
        if (lead?.leadDetail?.lead_detail) {
            updatedValues = {
                ...updatedValues,
                ...((lead?.leadDetail?.lead_detail?.inbound_lt_office_id && { inbound_lt_office_id: `${lead?.leadDetail?.lead_detail?.inbound_lt_office_id}` }) || {}),
                ...((lead?.leadDetail?.lead_detail?.sent_to_agent_date && { sent_to_agent_date: `${lead?.leadDetail?.lead_detail?.sent_to_agent_date}` }) || {})
            };
        }
        if (lead?.leadDetail?.lead_agents?.length) {
            updatedValues.standard_price = lead?.leadDetail?.lead_agents?.[0]?.standard_price || 0;
            updatedValues.final_standard_price = lead?.leadDetail?.lead_agents?.[0]?.final_standard_price || 0;
            const lead_agents = lead?.leadDetail?.lead_agents?.reduce((result, item) => {
                if (![AgentEntryType.Old, AgentEntryType.New].includes(item?.entry_type)) result[item?.agent_id] = item;
                return result;
            }, {});
            updatedValues.lead_agents = lead_agents;
        }
        setSendToAgentInput((currentValue) => ({
            ...currentValue,
            ...updatedValues
        }));
        if (lead?.state_id === STATUS_CODES.SENT_TO_AGENT) {
            setIsReadOnly(true);
        }
        if (lead?.leadDetail?.id) {
            setStatusTrackingUrl(createStatusTrackingLink(lead?.leadDetail?.id));
        } else {
            setStatusTrackingUrl("");
        }
    };

    let ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        return options;
    }, [masterdata]);

    useEffect(() => {
        if (
            sendToAgentInput?.inbound_lt_office_id &&
            sendToAgentInput?.agent_id &&
            sendToAgentInput?.service_type_id
        ) {
            ltoAgentServiceCharge(FETCH_TYPE.FEE);
            ltoAgentServiceCharge(FETCH_TYPE.TAT);
        } else {
            setSendToAgentInput((currentValue) => ({
                ...currentValue,
                agent_fee: null,
                standard_ttp: null,
            }));
        }
        if (sendToAgentInput?.agent_id) {
            ltoAgentServiceCharge(FETCH_TYPE.FEE);
            ltoAgentServiceCharge(FETCH_TYPE.TAT);
        }
    }, [sendToAgentInput?.inbound_lt_office_id, sendToAgentInput?.agent_id, sendToAgentInput?.service_type_id]);

    const createAcceptanceLink = ({ lead_id, agent_id }) => `${window.location.host}/title-transfer-request/${encode(lead_id)}/${encode(agent_id)}`;
    const createStatusTrackingLink = (lead_id) => `${window.location.host}/status-tracking/${encode(lead_id)}`;

    const ltoAgentList = (lt_office_id, service_type_id) => {
        executeGraphQLQuery(AGENT_LIST_FOR_STA(), client, { lt_office_id, service_type_id })
            .then((resp) => {
                if (resp?.data?.agent_list_for_sta) {
                    let { mapped, unmapped } = resp?.data?.agent_list_for_sta?.reduce((result, agent) => {
                        result[agent?.is_mapped ? `mapped` : `unmapped`].push(agent);
                        return result;
                    }, { mapped: [], unmapped: [] });
                    setAgentList(resp?.data?.agent_list_for_sta);
                    setAllAgentList({ mapped, unmapped });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCopy = (url) => {
        if (!url) return;
        navigator.clipboard
            .writeText(url)
            .then(() => {
                toast.success('Copied!');
            })
            .catch((err) => {
                toast.error('Failed to copy!');
            });
    };

    const ltoAgentServiceCharge = (fetch_type) => {
        const variables = {
            inbound_lt_office_id: +sendToAgentInput?.inbound_lt_office_id,
            agent_id: +sendToAgentInput?.agent_id,
            service_type_id: +sendToAgentInput?.service_type_id,
            fetch_type
        };
        executeGraphQLQuery(LTO_AGENT_SERVICE_CHARGE, client, { lto_agent_service: variables })
            .then((resp) => {
                const charges = resp?.data?.lto_agent_service_charge || {};
                if (charges?.fetch_type === FETCH_TYPE.FEE) {
                    setSendToAgentInput((currentValue) => ({
                        ...currentValue,
                        proposed_agent_fee: charges?.agent_fee || 0,
                        agent_fee: Number(lead?.leadDetail?.lead_detail?.agent_fee) || charges?.agent_fee || 0,
                        proposed_standard_ttp: charges?.service_details?.standard_price || 0,
                        standard_ttp: Number(lead?.leadDetail?.lead_detail?.standard_ttp) || charges?.service_details?.standard_price || 0,
                    }));
                }
                else if (charges?.fetch_type === FETCH_TYPE.TAT) {
                    setSendToAgentInput((currentValue) => ({
                        ...currentValue,
                        agent_tat: charges?.agent_tat || Number(lead?.leadDetail?.lead_detail?.agent_tat) || 0,
                    }));

                }
            })
            .catch((err) => {
                toast.error(err);
            });
    };

    const handleAgentInputChange = async (data, agent_id) => {
        const sendToAgentInp = sendToAgentInput || {};
        const lead_agents = sendToAgentInput?.lead_agents || {};
        if (data?.target?.id && agent_id) {
            lead_agents[agent_id][data?.target?.id] = +data?.target?.value;
        }
        else if (data?.target?.id === "final_standard_price") {
            sendToAgentInput[data?.target?.id] = +data?.target?.value;
        }
        setSendToAgentInput((currentValue) => ({
            ...currentValue,
            ...sendToAgentInp,
            lead_agents
        }));
    };

    const handleChange = async (data, field_name) => {
        const newValues = {};
        if (data.value && field_name !== "sent_to_agent_date" && field_name) {
            newValues[field_name] = data.value;
        }

        if (data?.target?.id) {
            newValues[data?.target?.id] = data?.target?.value;
        }

        if (field_name === 'sent_to_agent_date' && data) {
            newValues[field_name] = data;
        }
        if (field_name === "inbound_lt_office_id") {
            newValues.agent_id = null;
            await ltoAgentList(parseInt(newValues['inbound_lt_office_id']), sendToAgentInput?.service_type_id);
        }
        setSendToAgentInput((currentValue) => ({
            ...currentValue,
            ...newValues,
        }));
    };

    const handleSubmit = async () => {
        const isMultipleAgents = Object.keys(sendToAgentInput?.lead_agents || {})?.length > 1 ? true : false;
        let lead_id = lead?.leadDetail?.id || 0;
        let send_to_agent = {
            lead_id: lead_id,
            inbound_lt_office_id: +sendToAgentInput?.inbound_lt_office_id,
            end_user_link: sendToAgentInput?.end_user_link || createStatusTrackingLink(lead_id) || '',
            lead_agents: Object.keys(sendToAgentInput?.lead_agents)?.map(agent_id => ({
                lead_id,
                agent_id: +agent_id,
                agent_name: sendToAgentInput?.lead_agents?.[agent_id]?.label || sendToAgentInput?.lead_agents?.[agent_id]?.agent_name || "",
                agent_fee: +sendToAgentInput?.lead_agents?.[agent_id]?.agent_fee || 0,
                agent_tat: +sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat || 0,
                standard_price: isMultipleAgents ? +sendToAgentInput?.standard_price || 0 : +sendToAgentInput?.lead_agents?.[agent_id]?.standard_price || 0,
                final_agent_fee: +sendToAgentInput?.lead_agents?.[agent_id]?.final_agent_fee || 0,
                final_agent_tat: +sendToAgentInput?.lead_agents?.[agent_id]?.final_agent_tat || 0,
                final_standard_price: isMultipleAgents ? +sendToAgentInput?.final_standard_price || 0 : +sendToAgentInput?.lead_agents?.[agent_id]?.final_standard_price || 0,
                link: sendToAgentInput?.lead_agents?.[agent_id]?.link || createAcceptanceLink({ lead_id, agent_id }) || '',
            })),
            sent_to_agent_date: sendToAgentInput?.sent_to_agent_date,
            remarks: sendToAgentInput?.remarks || '',
        },
            variables = { user_id, role_id, send_to_agent };

        executeGraphQLMutation(SEND_TO_AGENT, variables, client)
            .then(async (resp) => {
                if (resp?.data?.send_to_agent) {
                    toast.success(resp?.data?.send_to_agent?.message);
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(getLoanDetail(lead_id, client));
                await getContext.getLeadHistory(lead_id);
                // navigateToNext();
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    let SendToAgentForm = useMemo(() => {
        return FORM_FIELDS.map((field) => {
            return field;
        });
    }, []);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
        return ![LEAD_STATUS.AGENT_SELECTION_REJECTED_BY_TL, LEAD_STATUS.REJECTED_BY_AGENT].includes(lead?.leadDetail?.status_id) && lead_status_history?.filter((v) => [LEAD_STATUS.SENT_TO_AGENT, LEAD_STATUS.AGENT_SELECTION_PENDING_AT_TL, LEAD_STATUS.AGENT_SELECTION_APPROVED_BY_TL].includes(v.status_id))?.length ? true : false;
    }, [lead_status_history, lead]);

    // Edit final ammount after send to agent button enable/disable
    const isFinalFieldsDisabled = useMemo(() => {
        return ![LEAD_STATUS.DOCUMENTS_UPLOADED, LEAD_STATUS.SENT_TO_AGENT, LEAD_STATUS.AGENT_SELECTION_REJECTED_BY_TL, LEAD_STATUS.REJECTED_BY_AGENT, LEAD_STATUS.EXCEPTION_CASE_APPROVED_BY_TL].includes(lead?.leadDetail?.status_id);
    }, [lead?.leadDetail?.status_id]);


    const filterAgents = (event) => {
        setSearchText(event?.target?.value);
        let { mapped, unmapped } = agentList?.reduce((result, agent) => {
            const isPresent = agent?.label?.toLowerCase().includes(event?.target?.value?.toLowerCase());
            if (isPresent) {
                result[agent?.is_mapped ? `mapped` : `unmapped`].push(agent);
            }
            return result;
        }, { mapped: [], unmapped: [] });
        setAllAgentList({ mapped, unmapped });
    };

    const submitSendToAgent = () => {
        if (Object.keys(sendToAgentInput?.lead_agents || {})?.length > 1) {
            setConfirmSelectAgents(!confirmSelectAgents);
        }
        else if (Object.keys(sendToAgentInput?.lead_agents || {})?.length === 1) {
            closeAllPopup();
        }
    };
    const closeAllPopup = () => {
        setAgentListPopup(false);
        setConfirmSelectAgents(false);
    };

    const showModalAgentListPopup = () => {
        setAgentListPopup(true);
        // document.body.classList.add('overflow-hidden');
    };

    const hideModalAgentListPopup = () => {
        setAgentListPopup(false);
        // document.body.classList.remove('overflow-hidden');
    };

    const selectAgent = (event, agent_id) => {
        if (event?.target?.checked) {
            const sendToAgentInp = sendToAgentInput || {};
            const lead_agents = sendToAgentInput?.lead_agents || {};
            if (!sendToAgentInp?.standard_price && serviceKeyValue) {
                sendToAgentInp.standard_price = serviceKeyValue?.[sendToAgentInput?.service_type_id]?.standard_price || 0;
                sendToAgentInp.final_standard_price = serviceKeyValue?.[sendToAgentInput?.service_type_id]?.standard_price || 0;
            }
            if (Object.keys(lead_agents)?.length >= MAX_AGENT_LIMIT) {
                toast.error(`Maximun ${MAX_AGENT_LIMIT} agents can be selected!`);
                return;
            }
            lead_agents[agent_id] = agentList?.find(item => item?.id === agent_id) || {};
            lead_agents[agent_id] = {
                ...lead_agents[agent_id],
                final_agent_fee: lead_agents[agent_id]?.agent_fee || 0,
                final_agent_tat: lead_agents[agent_id]?.agent_tat || 0,
                final_standard_price: lead_agents[agent_id]?.standard_price || 0,
                link: createAcceptanceLink({ agent_id, lead_id: lead?.leadDetail?.id }) || ""
            };
            setSendToAgentInput(prev => ({
                ...prev,
                ...sendToAgentInp,
                lead_agents
            }));
        }
        else {
            const lead_agents = sendToAgentInput?.lead_agents || {};
            delete lead_agents[agent_id];
            setSendToAgentInput(prev => ({
                ...prev,
                lead_agents
            }));
        }
    };
    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const sectionInfo = { section: 'Sent to Agent', sub_section: 'Sent to Agent' };
    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>Send To Agent</h2>
                    </div>
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleSubmit}
                        aria-label="lead form field basic"
                    >
                        <div className="row">
                            {SendToAgentForm.map((field) =>
                                ['text', 'pattern-format'].includes(field.type) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={sendToAgentInput?.[field?.name] || ""}
                                            placeholder=" "
                                            label={field.label}
                                            onChange={handleChange}
                                            format={field.ApplyNumberformat ? field.format : ''}
                                            validations={field.validations}
                                            validationError={sendToAgentInput[field.name] ? field.validationError : ''}
                                            readOnly={isReadOnly || isSubmitDisabled}
                                            required={field?.required}
                                            showAsterisk={field.showAsterisk}
                                        />
                                    </fieldset>
                                ) : (field.type === 'dropdown' && ['agent_id'].includes(field?.name)) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`} onClick={() => !(isReadOnly || isSubmitDisabled) && showModalAgentListPopup()}>
                                        <div className='sta-multi-select'>
                                            {(Object.keys(sendToAgentInput?.lead_agents || {})?.length > 1 && <p>Multiple Selected</p>) ||
                                                (Object.keys(sendToAgentInput?.lead_agents || {})?.length === 1 && <p>{sendToAgentInput?.lead_agents?.[Object.keys(sendToAgentInput?.lead_agents || {})?.[0]]?.label || sendToAgentInput?.lead_agents?.[Object.keys(sendToAgentInput?.lead_agents || {})?.[0]]?.agent_name} </p>) ||
                                                <p>Select Agent</p>}

                                            <i className='ic-keyboard_arrow_down'></i>
                                        </div>
                                    </fieldset>
                                ) : (field.type === 'dropdown' && !['agent_id'].includes(field?.name)) ? (
                                    <fieldset class={`single-select ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <FormsySelect
                                            name={field.name}
                                            id={field.id}
                                            inputProps={{
                                                options: ALL_OPTIONS?.[field?.optionsKey] || [],
                                                placeholder: field.label,
                                                className: 'react-select',
                                                classNamePrefix: 'react-select',
                                                value: field.name === 'agent_id' ? agentList?.filter(agent => agent.value === sendToAgentInput[field.name])
                                                    : (ALL_OPTIONS &&
                                                        ALL_OPTIONS[field.optionsKey] &&
                                                        ALL_OPTIONS[field.optionsKey].filter(
                                                            ({ value }) => value === sendToAgentInput[field.name],
                                                        )) || "",
                                                isDisabled: isReadOnly || isSubmitDisabled,
                                            }}
                                            required={field?.required}
                                            showAsterisk={field?.showAsterisk}
                                            value={sendToAgentInput?.[field?.name] || ""}
                                            onChange={(data) => handleChange(data, field.name)}
                                        />
                                    </fieldset>
                                ) : ['date-picker'].includes(field?.type) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <div className="material">
                                            <div className="datepicker send-to-agent-date-picker">
                                                <FormsyDatePicker
                                                    onDateSelect={(data) => handleChange(data, field?.name)}
                                                    placeholder={field?.label}
                                                    name={field.name}
                                                    selectedDate={(sendToAgentInput?.[field?.name] && new Date(sendToAgentInput?.[field?.name])) || ""}
                                                    value={(sendToAgentInput?.[field?.name] && new Date(sendToAgentInput?.[field?.name])) || ""}
                                                    minDate={new Date().setMonth(new Date().getMonth() - 2)}
                                                    maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    showTimeSelect={true}
                                                    validations={field?.validations}
                                                    validationError={sendToAgentInput[field?.name] ? field?.validationError : ''}
                                                    required={field?.required}
                                                    hasError={''}
                                                    clearDate={false}
                                                    readOnly={isReadOnly || isSubmitDisabled}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                ) : null,
                            )}
                        </div>
                        {
                            (Object.keys(sendToAgentInput?.lead_agents || {})?.length ? <AgentServiceTatViewAction sendToAgentInput={sendToAgentInput} handleCopy={handleCopy} handleAgentInputChange={handleAgentInputChange} isSubmitDisabled={isSubmitDisabled} isFinalFieldsDisabled={isFinalFieldsDisabled} serviceKeyValue={serviceKeyValue} statusTrackingUrl={statusTrackingUrl} /> : <></>)
                        }
                        < div className="btn-save-remarks p-sm-t" >
                            {(isSubmitDisabled && isFinalFieldsDisabled) ||
                                !allowSubmit ||
                                getContext.markAsFreezed ||
                                accessCondition ||
                                isLeadAssigned || isAgentSelected ? (
                                <span className="span-disabled">Save & Next</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                            <button
                                aria-label="remarks history"
                                type="button"
                                className="btn-line"
                                onClick={showModalViewTimeLine}
                            >
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </Formsy>
                </div>
            </div >
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>

            <div className="view-timeline-popup agent-list-popup">
                <Modal show={AgentListPopup} handleClose={hideModalAgentListPopup}>
                    <div className="modal-header">
                        <h2>Select Agents</h2>
                    </div>
                    <div className='modal-body'>
                        <div className='multi-select-agent-list'>
                            <fieldset class="search-bx p-lg-b">
                                <div class="material">
                                    <input
                                        id=""
                                        type="text"
                                        aria-label="search text"
                                        name="search_text"
                                        value={searchText || ''}
                                        onChange={(e) => filterAgents(e)}
                                        class="form-input"
                                        title="SEARCH_TEXT"
                                        placeholder="Search Agent"
                                    />
                                    <i class="ic-search"></i>
                                </div>
                            </fieldset>
                            <div className='assign-agnet-list'>
                                <span>Assigned Agents</span>
                                <ul>
                                    {allAgentList?.mapped?.map(agent => (
                                        <li class="custom-control custom-checkbox">
                                            <input
                                                id={`user_${agent?.id}`}
                                                type="checkbox"
                                                class="custom-control-input"
                                                onChange={(e) => selectAgent(e, agent?.id)}
                                                checked={sendToAgentInput?.lead_agents?.[agent?.id] || false} />
                                            <label for={`user_${agent?.id}`} class="custom-control-label">{agent?.label}</label>
                                        </li>
                                    ))}
                                </ul>

                                <span>Other Agents</span>
                                <ul>
                                    {allAgentList?.unmapped?.map(agent => (
                                        <li class="custom-control custom-checkbox">
                                            <input
                                                id={`user_${agent?.id}`}
                                                type="checkbox"
                                                class="custom-control-input"
                                                onChange={(e) => selectAgent(e, agent?.id)}
                                                checked={sendToAgentInput?.lead_agents?.[agent?.id] || false} />
                                            <label for={`user_${agent?.id}`} class="custom-control-label">{agent?.label}</label>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className='modal-footer'>
                        <div className='select-agent-btn'>
                            {!Object.keys(sendToAgentInput?.lead_agents || {})?.length ?
                                <span className="span-disabled">{`${Object.keys(sendToAgentInput?.lead_agents || {})?.length} Selected - Submit Now`}</span>
                                :
                                <button onClick={() => submitSendToAgent()} className="btn-primary" type='submit'>{`${Object.keys(sendToAgentInput?.lead_agents || {})?.length} Selected - Submit Now`}</button>}
                        </div>
                    </div>

                </Modal>
            </div>

            <div className="confirm-agents-popup">
                <Modal show={confirmSelectAgents} handleClose={() => setConfirmSelectAgents(!confirmSelectAgents)}>
                    <div className="modal-header">
                        <h2>Are you sure ?</h2>
                    </div>
                    <div className="modal-body">
                        Multiple agent selections will need your TL approval.
                    </div>
                    < div className="btn-submit top-btn-none m-lg-t">
                        <button onClick={() => closeAllPopup()} className="btn-primary btn-auto-width">Yes, Send to TL for Approval</button>
                    </div>
                </Modal>
            </div >
        </div >
    );
};
export default SendToAgentForm;
