import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { CHECK_ASSIGN_USER, DOC_STATUS_MAP, IS_USER_ACCESS_PAGE, LEAD_STATUS, PERMISSION_CONFIG } from '../../../config/constants';
import { getLoanDetail } from '../../../store/action/allAction';
import FormsyTextAreaAutosize from '../../elements/FormsyTextAreaAutosize';
import Modal from '../../elements/Modal';
import DocumentGallery from '../DocumentGallery';
import { APPROVE_EXCEPTION_BY_TL, CANCEL_EXCEPTION, GET_EXCEPTION_DETAILS, REJECT_EXCEPTION_BY_TL, SEND_EXCEPTION_TO_TL } from '../../../services/leads.gql';
import Loader from '../../elements/Loader';

const exception_reason_map = {
    1: "Vender/Vendee sign are missing in Deed of sale",
    2: "Vendor/Vendee sign not matching with ID",
    3: "Expired Primary ID",
    4: "No PH ID",
    5: "Stencil not in the required format",
    6: "Missing docs",
    7: "Other Mistakes"
};
const Exceptional = (props) => {
    const { is_edit_access, is_super_admin } = IS_USER_ACCESS_PAGE(PERMISSION_CONFIG.lead_exception.label, PERMISSION_CONFIG.lead_exception.approve_reject);
    const [loading, setLoading] = useState(false);
    const [exception, setException] = useState({
        id: "",
        docs: [],
        remarks: "",
        tl_remarks: "",
        exception_status: null
    });
    const { lead } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead
    }));
    const [defaultDoc, setDeafultDoc] = useState('');
    const [showApproveException, setShowApproveExcpetion] = useState(false);
    const [showRejectException, setShowRejectException] = useState(false);
    const [agent_remarks, setAgentRemarks] = useState("");
    const [approveButtonDisabled, setApproveButtonDisabled] = useState(false);
    const [rejectButtonDisabled, setRejectButtonDisabled] = useState(false);
    const [verifiedDocs, setVerifiedDocs] = useState([]);
    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'vehicle-details')?.is_edit_access;
    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;
    let tlActionsDisabled = (lead.leadDetail?.tl_assigned?.toString() !== UserInfo.id?.toString()) && !UserInfo.role_id.includes(1);
    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'vehicle-details').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);
    const getContext = useOutletContext();

    const documentCategoryList = getContext.docList || [];

    useEffect(() => {
        let verified = [];
        lead?.leadDetail?.lead_docs?.forEach(doc => {
            if (doc.doc_status === DOC_STATUS_MAP.APPROVED)
                verified.push(doc.doc_id.toString());
        });
        setVerifiedDocs(verified);
        getException();
    }, [lead]);

    const getException = async () => {
        let variables = {
            getExceptionInput: {
                lead_id: lead?.leadDetail?.lead_detail?.lead_id,
            }
        };

        executeGraphQLQuery(GET_EXCEPTION_DETAILS(), client, variables)
            .then(res => {
                if (res?.data?.get_exception_details) {
                    setException({
                        id: res.data.get_exception_details.id?.toString(),
                        docs: res.data.get_exception_details.docs,
                        remarks: res.data.get_exception_details.remarks,
                        tl_remarks: res.data.get_exception_details.tl_remarks,
                        exception_status: res.data.get_exception_details.exception_status,
                    });
                }
            });

    };

    const sendToTL = async () => {
        setLoading(true);
        let agentApprovalInput = {
            lead_id: lead?.leadDetail?.lead_detail?.lead_id,
            exception_id: exception.id,
        };
        let variables = {
            api_called_by: "web",
            user_id,
            dealer_id: lead?.leadDetail?.dealer_id,
            agentApprovalInput,
        };

        try {
            const res = await executeGraphQLMutation(SEND_EXCEPTION_TO_TL(), variables, client);
            if (res.data?.send_to_tl) {
                toast.success(res.data.send_to_tl.message);

                // Dispatch and wait for Redux state update
                dispatch(getLoanDetail(lead?.leadDetail?.id, client));

                // Ensure state is updated before navigating
                // navigate(0);
            }
        } catch (error) {
            toast.error(error.message || "Error occurred");
        } finally {
            setLoading(false);
        }
    };


    const approveByTL = async () => {
        setLoading(true);
        let agentApprovalInput = {
            lead_id: lead?.leadDetail?.lead_detail?.lead_id,
            exception_id: exception.id,
            remarks: agent_remarks
        };
        let variables = {
            api_called_by: "web",
            user_id,
            dealer_id: lead?.leadDetail?.dealer_id,
            agentApprovalInput: agentApprovalInput
        };

        executeGraphQLMutation(APPROVE_EXCEPTION_BY_TL(), variables, client)
            .then(async (res) => {
                if (res.data.approve_by_tl) {
                    toast.success(res.data.approve_by_tl.message);
                    setShowApproveExcpetion(false);
                    dispatch(getLoanDetail(lead?.leadDetail?.id, client));
                    //navigate(0)
                }
            })
            .catch((e) => {
                toast.error(e);
            })
            .finally(() => setLoading(false));
    };

    const rejectByTL = async () => {
        setLoading(true);
        let agentApprovalInput = {
            lead_id: lead?.leadDetail?.lead_detail?.lead_id,
            exception_id: exception.id,
            remarks: agent_remarks
        };
        let variables = {
            api_called_by: "web",
            user_id,
            dealer_id: lead?.leadDetail?.dealer_id,
            agentApprovalInput: agentApprovalInput
        };

        //console.log(variables)
        let curr_lead = lead?.leadDetail?.id;
        executeGraphQLMutation(REJECT_EXCEPTION_BY_TL(), variables, client)
            .then(async (res) => {
                if (res.data.reject_by_tl) {
                    toast.success(res.data.reject_by_tl.message);
                    setShowRejectException(false);
                    dispatch(getLoanDetail(curr_lead, client));
                    //navigate(0)
                }
            })
            .catch((e) => {
                toast.error(e);
            })
            .finally(() => setLoading(false));
    };

    const onCancel = () => {
        setLoading(true);
        let cancelException = {
            lead_id: lead?.leadDetail?.lead_detail?.lead_id,
            exception_id: exception.id
        };
        let variables = {
            api_called_by: "web",
            user_id,
            dealer_id: lead?.leadDetail?.dealer_id,
            cancelException: cancelException
        };

        //console.log(variables)
        executeGraphQLMutation(CANCEL_EXCEPTION(), variables, client)
            .then(async (res) => {
                let status = exception.exception_status;
                if (res.data.cancel_exception) {
                    toast.success(res.data.cancel_exception.message);
                    dispatch(getLoanDetail(lead?.leadDetail?.id, client));
                    if (status === LEAD_STATUS.EXCEPTIONAL_CASE_PENDING_AT_DEALER) {
                        navigate(`/lead-detail/customer-details/vehicle-details/${lead_id}`);
                    }
                }
            })
            .catch((e) => {
                toast.error(e);
            }).finally(() => setLoading(false));
    };

    return (
        <>
            {loading && <Loader />}
            <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
                <div className="image-tab-panel">
                    <DocumentGallery documentCategoryList={documentCategoryList} defaultDoc={defaultDoc} />
                </div>
                <div className="form-tab-right-panel">
                    <div className="lead-detail-from-outer">
                        <div className="lead-form-heading">
                            <h2>Exceptional Case</h2>
                        </div>
                        {
                            exception.exception_status === LEAD_STATUS.EXCEPTIONAL_CASE_PENDING_AT_DEALER && lead?.leadDetail?.doc_status !== DOC_STATUS_MAP.APPROVED
                            && <div className='dealer-approvaler-outer'>
                                <div className='approver-heading'>
                                    <h3>Waiting for Dealer Approval</h3>
                                </div>
                                <br />
                                <div className='opt-btn'>
                                    <button className='btn-primary btn-auto-width' onClick={sendToTL} disabled={isLeadAssigned || getContext?.markAsFreezed}>Send to TL</button>
                                    <button className='btn-line btn-auto-width' onClick={onCancel} disabled={isLeadAssigned || getContext?.markAsFreezed}>Cancel</button>
                                </div>
                            </div>
                        }

                        {(exception.exception_status === LEAD_STATUS.EXCEPTIONAL_CASE_PENDING_AT_TL && ((is_edit_access && lead?.leadDetail?.tl_assigned?.toString() === user_id?.toString()) || is_super_admin)) ?
                            <div className='tl-waiting-outer'>
                                <div className='approver-heading'>
                                    <p>Waiting for your approval</p>
                                </div>
                            </div> :
                            exception.exception_status === LEAD_STATUS.EXCEPTIONAL_CASE_PENDING_AT_TL ?
                                <div className='tl-waiting-outer'>
                                    <div className='approver-heading'>
                                        <h3>Waiting for TL Approval.</h3>
                                        <p>TL approval is mandatory</p>
                                    </div>
                                    <button className='btn-line btn-auto-width' onClick={onCancel} disabled={isLeadAssigned || getContext?.markAsFreezed}>
                                        Cancel
                                    </button>
                                </div> : <></>
                        }

                        {exception.exception_status === LEAD_STATUS.EXCEPTION_CASE_APPROVED_BY_TL &&
                            <div className='tl-approved-outer'>
                                <div className='approver-heading'>
                                    <h3>Approved From TL <i className='ic-check'></i></h3>
                                </div>
                                <div className='tl-comment'>
                                    <span>TL Comments</span>
                                    <p>{exception.tl_remarks}</p>
                                </div>
                            </div>
                        }

                        {exception.exception_status === LEAD_STATUS.EXCEPTION_CASE_REJECTED_BY_TL &&
                            <div className='tl-reject-outer'>
                                <div className='approver-heading'>
                                    <h3>Rejcted By TL <i className='ic-clearclose'></i></h3>
                                </div>
                                <div className='tl-comment'>
                                    <span>TL Comments</span>
                                    <p>{exception.tl_remarks}</p>
                                </div>
                            </div>
                        }

                        {lead?.leadDetail?.doc_status === DOC_STATUS_MAP.APPROVED && <> <br /><div className='tl-approved-outer'>

                            <div className='tl-comment'>
                                <p>Documents approved</p>
                            </div>
                        </div></>}




                        <div className='exceptional-reason-outer'>
                            <h3>Exceptional Reason</h3>
                            <div className='exceptional-reason-list'>
                                <ul>
                                    {exception?.docs?.map((doc) => <li>
                                        <label>{exception_reason_map[doc?.reason_id]}</label>
                                        <div class="selected-chips">
                                            <ul>
                                                {JSON.parse(doc?.reason)?.map((reason) => verifiedDocs.includes(reason?.value?.toString()) ? <></> : <li style={{ cursor: 'pointer' }} onClick={() => setDeafultDoc(reason?.value?.toString())}>
                                                    {reason?.label}
                                                </li>)}
                                            </ul>
                                        </div>
                                    </li>)}
                                </ul>
                            </div>
                        </div>
                        {exception.remarks && <div className='exceptional-comments'>
                            <span>Exceptional Comments</span>
                            <p>{exception.remarks}</p>
                        </div>}
                        <br />
                        {(exception.exception_status === LEAD_STATUS.EXCEPTIONAL_CASE_PENDING_AT_TL && ((is_edit_access && lead?.leadDetail?.tl_assigned?.toString() === user_id?.toString()) || is_super_admin)) ?
                            <div className='opt-btn row'>
                                <button className='btn-primary btn-auto-width' onClick={() => setShowApproveExcpetion(true)} disabled={tlActionsDisabled || getContext?.markAsFreezed}>Approve</button> &nbsp;
                                <button className='btn-line btn-auto-width' onClick={() => setShowRejectException(true)} disabled={tlActionsDisabled || getContext?.markAsFreezed}>Reject</button>
                            </div> : <></>}
                    </div>

                    <div className="view-timeline-popup exception-popup">
                        <Modal show={showApproveException} handleClose={() => {
                            setShowApproveExcpetion(false);
                            setAgentRemarks("");
                        }}>
                            <div className="modal-header">
                                <h2>Are you sure you want to approve this exception case?</h2>
                            </div>
                            <div className='modal-body'>
                                <Formsy
                                    className="lead-form-filed"
                                    autoComplete="off"
                                    onValid={() => setApproveButtonDisabled(true)}
                                    onInvalid={() => setApproveButtonDisabled(false)}
                                    onValidSubmit={approveByTL}
                                    aria-label="lead form field basic"
                                >
                                    <div className='row'>
                                        <fieldset className="form-field p-md-b col-md-12">
                                            <div className="material">
                                                <FormsyTextAreaAutosize
                                                    id={"agent_approval"}
                                                    name={"agent_approval"}
                                                    value={agent_remarks}
                                                    placeholder=""
                                                    label={"Agent Remarks"}
                                                    onChange={(e) => setAgentRemarks(e.target.value)}
                                                    required={true}
                                                    className="form-input"
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                    <button
                                        className='btn-primary'
                                        type='submit'
                                        disabled={!approveButtonDisabled || getContext?.markAsFreezed}
                                    >
                                        Submit Application
                                    </button>
                                </Formsy>
                            </div>
                        </Modal>
                    </div>

                    <div className="view-timeline-popup exception-popup">
                        <Modal show={showRejectException} handleClose={() => {
                            setShowRejectException(false);
                            setAgentRemarks("");
                        }}>
                            <div className="modal-header">
                                <h2>Are you sure you want to reject this exception case?</h2>
                            </div>
                            <div className='modal-body'>
                                <Formsy
                                    className="lead-form-filed"
                                    autoComplete="off"
                                    onValid={() => setRejectButtonDisabled(true)}
                                    onInvalid={() => setRejectButtonDisabled(false)}
                                    onValidSubmit={rejectByTL}
                                    aria-label="lead form field basic"
                                >
                                    <div className='row'>
                                        <fieldset className="form-field p-md-b col-md-12">
                                            <div className="material">
                                                <FormsyTextAreaAutosize
                                                    id={"agent_approval"}
                                                    name={"agent_approval"}
                                                    value={agent_remarks}
                                                    placeholder=""
                                                    label={"Agent Remarks"}
                                                    onChange={(e) => setAgentRemarks(e.target.value)}
                                                    required={true}
                                                    className="form-input"
                                                />
                                            </div>
                                        </fieldset>
                                    </div>
                                    <button
                                        className='btn-primary'
                                        type='submit'
                                        disabled={!rejectButtonDisabled || getContext?.markAsFreezed}
                                    >
                                        Submit Application
                                    </button>
                                </Formsy>
                            </div>
                        </Modal>
                    </div>
                </div>

            </div >
        </>
    );
};
export default Exceptional;