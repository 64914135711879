import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { CHECK_ASSIGN_USER, DATE_FORMAT, DOC_STATUS_MAP, IS_USER_ACCESS_PAGE, LEAD_STATUS, SERVICE_TYPE_ID_TOGGLE, SERVICE_TYPE_VALIDATION_IDS, fieldsNeedToDisable, getKeysDataIfExist } from '../../../config/constants';
import { SAVE_CUSTOMER_VEHICLE_DETAILS } from '../../../services/customer.gql';
import { getLoanDetail, getMasterDataMMV, getVehicleUsage } from '../../../store/action/allAction';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import FormsyTextAreaAutosize from '../../elements/FormsyTextAreaAutosize';
import Modal from '../../elements/Modal';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import DocumentGallery from '../DocumentGallery';
import FormFields from '../formFields/vehicleDetailsFormFields.json';
import * as moment from 'moment';
import { GET_DOCUMENT_CATEGORY_LIST } from '../../../services/leads.gql';
import AddExceptionalCase from './AddExceptionalCase';

const FORM_FIELDS = FormFields['VEHICLE_DETAILS'];

const VehicleDetailsForm = (props) => {
    const getContext = useOutletContext();
    const [vehicleDetails, setVehicleDetails] = useState({});
    const [existingVehicleDetails, setExistingVehicleDetails] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [orExpiryConfirm, setOrExpiryConfirm] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [allOptions, setAllOptions] = useState({});
    const [documentCategoryList, setDocumentCategoryList] = useState(getContext.docList || []);
    const [Exceptionalcase, setExceptionalcase] = useState(false);
    const { masterdata, lead, remarksHistory, leadHistory } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadHistory: lead?.leadDetail?.lead_status_history,
    }));

    const client = useApolloClient();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { lead_id } = useParams();
    let accessCondition = !IS_USER_ACCESS_PAGE('loanDetail', 'vehicle-details')?.is_edit_access;

    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.lead_assigned_to);
    }

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    let user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;

    let isLeadAssigned = IS_USER_ACCESS_PAGE('loanDetail', 'vehicle-details').is_super_admin
        ? false
        : !(lead?.leadDetail?.lead_assigned_to && lead?.leadDetail?.lead_assigned_to === user_id);

    useEffect(() => {
        docCategoryList(lead?.leadDetail?.service_type_id, +lead?.leadDetail?.service_type_id !== 3 ? lead?.leadDetail?.transfer_type_id : null);
    }, [lead?.leadDetail?.service_type_id, lead?.leadDetail?.transfer_type_id]);
    const docCategoryList = (service_type_id, transfer_type_id) => {
        ;
        executeGraphQLQuery(GET_DOCUMENT_CATEGORY_LIST(service_type_id, transfer_type_id), client)
            .then((resp) => {
                if (resp?.data?.get_document_list) {
                    setDocumentCategoryList([...resp?.data?.get_document_list]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (masterdata) getMasterDataOptions();
        if (lead?.leadDetail?.lead_vehicle_detail?.make_id) getModel(lead?.leadDetail?.lead_vehicle_detail?.make_id);
        if (lead?.leadDetail?.lead_vehicle_detail?.model_id) getVersion(lead?.leadDetail?.lead_vehicle_detail?.model_id);
    }, [masterdata, lead?.leadDetail?.lead_vehicle_detail]);

    useEffect(() => {
        dispatch(getVehicleUsage(client));
    }, []);

    useEffect(() => {
        if (lead?.leadDetail?.lead_vehicle_detail) {
            let vehicle_details = lead.leadDetail.lead_vehicle_detail;
            let vehicleData = { ...vehicle_details };
            if (vehicleData?.vehicle_usage) {
                setAllOptions(prev => ({
                    ...prev, vehicle_type: masterdata?.masterdataVehicleUsage?.vehicle_usage?.find(el => el.id === vehicleData?.vehicle_usage)?.vehicle_type || []
                }));
            }
            if (vehicleData?.vehicle_category_id === 2) {
                vehicleData['vehicle_usage_hide_fields'] = true;
                vehicleData['vehicle_type_id_hide_fields'] = true;
            } else {
                vehicleData['vehicle_usage_hide_fields'] = false;
                vehicleData['vehicle_type_id_hide_fields'] = false;
            }
            setVehicleDetails(vehicleData);
            setExistingVehicleDetails(vehicleData);
        }
    }, [lead, lead?.leadDetail?.lead_vehicle_detail?.vehicle_usage, lead?.leadDetail?.lead_vehicle_detail?.vehicle_category_id]);

    useEffect(() => {
        setAllOptions(prev => ({
            ...prev, vehicle_usage: masterdata?.masterdataVehicleUsage?.vehicle_usage || []
        }));

    }, [masterdata?.masterdataVehicleUsage?.vehicle_usage]);

    useEffect(() => {
        if (vehicleDetails?.vehicle_usage) {
            setAllOptions(prev => ({
                ...prev, vehicle_type: allOptions?.vehicle_usage?.find(el => el.id === vehicleDetails?.vehicle_usage)?.vehicle_type || []
            }));
        }

    }, [masterdata, vehicleDetails?.vehicle_usage]);

    const showModalExceptionalcase = () => {
        setExceptionalcase(true);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalExceptionalcase = () => {
        setExceptionalcase(false);
        document.body.classList.remove('overflow-hidden');
    };

    useEffect(() => {
        if ((vehicleDetails?.vehicle_type_id && vehicleDetails?.vehicle_type_id !== 6) || vehicleDetails?.vehicle_category_id === 2) {
            let vehicle_type_id = (vehicleDetails?.vehicle_category_id === 2) ? 999 : vehicleDetails?.vehicle_type_id;
            dispatch(getMasterDataMMV(client, vehicle_type_id));
            getMasterDataOptions();
        }
    }, [vehicleDetails?.vehicle_type_id, vehicleDetails?.vehicle_category_id]);

    const getMasterDataOptions = async () => {
        let options = masterdata ? { ...masterdata.data, ...(masterdata.masterdatammv || {}), ...(masterdata.masterdataVehicleUsage || {}) } : {};
        options['make'] = options.make?.map((make) => ({ value: make.id, label: make.make })) || [];

        options['model'] = options.model?.map((model) => ({ value: model.id, label: model.m, mk_id: model.mk_id })) || [];

        options['version'] = options.version?.map((version) => ({
            value: version.vn_id,
            label: version.vn,
            mk_id: version.mk_id,
            md_id: version.md_id,
        })) || [];

        options['make'].push({ value: 0, label: 'Other' });
        options['model'].push({ value: 0, label: 'Other' });
        options['version'].push({ value: 0, label: 'Other' });
        options['make_year'] = options?.make_year || [];
        setAllOptions(prev => ({ ...prev, ...options }));
    };

    const getModel = (make_id) => {
        if (make_id || make_id === 0) {
            let options = masterdata ? { ...masterdata.masterdatammv } : {};
            options['model'] =
                make_id !== 0
                    ? (options?.model
                        ?.reduce((result, model) => {
                            if (model.mk_id === make_id) result.push({ value: model.id, label: model.m, mk_id: model.mk_id });
                            return result;
                        }, [])) || []
                    : [];
            options['model'].push({ value: 0, label: 'Other' });
            let all_options = {};
            all_options['model'] = options['model'];
            if (make_id === 0) options['version'] = [{ value: 0, label: 'Other' }];
            else options['version'] = [];
            all_options['version'] = options['version'];
            setAllOptions(prev => ({ ...prev, ...all_options }));
        }
    };

    const getVersion = (model_id) => {
        if (model_id || model_id === 0) {
            let options = masterdata ? { ...masterdata?.masterdatammv } : {};
            options['version'] =
                model_id !== 0
                    ? (options?.version
                        ?.reduce((result, version) => {
                            if (version.md_id === model_id) result.push({
                                value: version.vn_id,
                                label: version.vn,
                                mk_id: version.mk_id,
                                md_id: version.md_id,
                            });
                            return result;
                        }, [])) || []
                    : [];
            options['version'].push({ value: 0, label: 'Other' });
            let all_options = {};
            all_options['version'] = options['version'];
            setAllOptions(prev => ({ ...prev, ...all_options }));
        }
    };

    const getMake = (vehicle_type) => {
        if (vehicle_type && Number(vehicle_type) !== 6) {
            setVehicleDetails((currentValue) => ({
                ...currentValue,
                'vehicle_type_id': vehicle_type,
            }));
        }
        // else {
        //     setAllOptions({ ...allOptions, make: [{ value: 9997, label: 'Other' }] });
        // }
    };

    const handleMakeChange = (sname, ovalue) => {
        vehicleDetails[sname] = ovalue.value;
        if (sname === 'make_id') {
            getModel(ovalue.value);
            if (ovalue.value === 0) {
                vehicleDetails['model_id'] = 0;
                vehicleDetails['variant_id'] = 0;
            }
            else {
                vehicleDetails['model_id'] = null;
                vehicleDetails['variant_id'] = null;
            }
        } else if (sname === 'model_id') {
            getVersion(ovalue.value);
            if (ovalue.value === 0) {
                vehicleDetails['variant_id'] = 0;
            }
            else {
                vehicleDetails['variant_id'] = null;
            }
        } else if (sname === 'vehicle_type_id') {
            getMake(ovalue.value);
            vehicleDetails[sname] = parseInt(ovalue.value);
            vehicleDetails['model_id'] = null;
            vehicleDetails['make_id'] = null;
            vehicleDetails['variant_id'] = null;
            vehicleDetails['make_name'] = null;
            vehicleDetails['model_name'] = null;
            vehicleDetails['variant_name'] = null;
        } else if (sname === 'vehicle_usage') {
            vehicleDetails[sname] = parseInt(ovalue.value);
            setAllOptions(prev => ({ ...prev, vehicle_type: ovalue?.vehicle_type || [] }));
            vehicleDetails['vehicle_type_id'] = null;
            vehicleDetails['model_id'] = null;
            vehicleDetails['make_id'] = null;
            vehicleDetails['variant_id'] = null;
            vehicleDetails['make_name'] = null;
            vehicleDetails['model_name'] = null;
            vehicleDetails['variant_name'] = null;
        } if (sname === 'vehicle_category_id') {
            if (parseInt(ovalue.value) === 2) {
                vehicleDetails['vehicle_usage_hide_fields'] = true;
                vehicleDetails['vehicle_type_id_hide_fields'] = true;
            } else {
                setAllOptions(prev => ({ ...prev, make: [], model: [], version: [] }));
                vehicleDetails['vehicle_usage_hide_fields'] = false;
                vehicleDetails['vehicle_type_id_hide_fields'] = false;
            }
            vehicleDetails[sname] = parseInt(ovalue.value);
            vehicleDetails['vehicle_type_id'] = null;
            vehicleDetails['vehicle_usage'] = null;
            vehicleDetails['model_id'] = null;
            vehicleDetails['make_id'] = null;
            vehicleDetails['variant_id'] = null;
            vehicleDetails['make_name'] = null;
            vehicleDetails['model_name'] = null;
            vehicleDetails['variant_name'] = null;
        }
        vehicleDetails['other_make_name'] = null;
        vehicleDetails['other_model_name'] = null;
        vehicleDetails['other_variant_name'] = null;
        setVehicleDetails({ ...vehicleDetails });
    };

    const handleInputChange = (event) => {
        if (event.target.id) {
            setVehicleDetails((currentValue) => ({
                ...currentValue,
                [event.target.id]: event.target.value,
            }));
        }
    };

    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            vehicleDetails[field_name] = data.value;
            if (field_name === "variant_id") {
                vehicleDetails['other_make_name'] = null;
                vehicleDetails['other_model_name'] = null;
                vehicleDetails['other_variant_name'] = null;
            }
            setVehicleDetails({ ...vehicleDetails });
        }
    };

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            const updatedFields = {};
            if (field_name === "or_expiry_date") {
                updatedFields.service_type_id = handleServiceIdChange(lead?.leadDetail?.created_date, lead?.leadDetail?.lead_vehicle_detail?.or_expiry_date, date, lead?.leadDetail?.service_type_id);
            }
            setVehicleDetails((currentValue) => ({
                ...currentValue,
                [field_name]: date,
                ...updatedFields
            }));
        }
    };

    const handleServiceIdChange = (created_date, or_expiry_date, or_expiry_date_new, service_type_id) => {
        created_date = moment(created_date)?.format(DATE_FORMAT) || created_date;
        or_expiry_date = moment(or_expiry_date)?.format(DATE_FORMAT) || or_expiry_date;
        or_expiry_date_new = moment(or_expiry_date_new)?.format(DATE_FORMAT) || or_expiry_date_new;
        if (!moment(or_expiry_date).isSame(moment(or_expiry_date_new)) && !SERVICE_TYPE_ID_TOGGLE?.NEUTRAL?.includes(service_type_id) && ((moment(or_expiry_date_new).isBefore(created_date) && !SERVICE_TYPE_VALIDATION_IDS.OLDER?.includes(service_type_id)) || (!moment(or_expiry_date_new).isBefore(created_date) && !SERVICE_TYPE_VALIDATION_IDS.GREATER?.includes(service_type_id)))) {
            service_type_id = SERVICE_TYPE_ID_TOGGLE[service_type_id];
        };
        return service_type_id;
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (data.value !== undefined && field_name) {
            setVehicleDetails((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };

    const handleSubmit = () => {
        if (!moment(vehicleDetails?.or_expiry_date).isSame(lead?.leadDetail?.lead_vehicle_detail?.or_expiry_date)) {
            toggleOrExpiryConfirmPopup();
            return;
        }
        saveVehicleDetails();
    };

    const saveVehicleDetails = () => {
        if (orExpiryConfirm) toggleOrExpiryConfirmPopup();
        let lead_id = lead?.leadDetail?.id || 0;
        let reqBody = {
            lead_id: lead_id,
            plate_number: vehicleDetails.plate_number || null,
            vehicle_usage: vehicleDetails.vehicle_usage,
            vehicle_type_id: vehicleDetails.vehicle_type_id,
            vehicle_category_id: +vehicleDetails.vehicle_category_id || 0,
            make_id: vehicleDetails.make_id || 0,
            model_id: vehicleDetails.model_id || 0,
            variant_id: vehicleDetails.variant_id || 0,
            make_name: !vehicleDetails?.other_make_name ?
                allOptions?.make?.filter(({ value }) => Number(value) === Number(vehicleDetails['make_id']))?.[0]
                    ?.label : null,
            model_name: !vehicleDetails?.other_model_name ?
                allOptions?.model?.filter(({ value }) => Number(value) === Number(vehicleDetails['model_id']))?.[0]
                    ?.label : null,
            variant_name: !vehicleDetails?.other_variant_name ?
                allOptions?.version?.filter(({ value }) => Number(value) === Number(vehicleDetails['variant_id']))?.[0]
                    ?.label : null,
            make_year: (vehicleDetails?.make_year && Number(vehicleDetails?.make_year)) || null,
            other_make_name: vehicleDetails?.other_make_name || null,
            other_model_name: vehicleDetails?.other_model_name || null,
            other_variant_name: vehicleDetails?.other_variant_name || null,
            engine_number: vehicleDetails.engine_number || null,
            chassis_number: vehicleDetails.chassis_number || null,
            fuel_type: Number(vehicleDetails.fuel_type) || null,
            car_color: vehicleDetails.car_color || null,
            or_expiry_date: vehicleDetails.or_expiry_date || null,
            remarks: vehicleDetails.remarks || null,
            updated_by: user_id || 0,
            ...getKeysDataIfExist(vehicleDetails, ["service_type_id"])
        };

        if (vehicleDetails?.vehicle_type_id === 6)
            reqBody = { ...reqBody, other_vehicle_type: "other" };

        const api_user_role = {
            api_called_by: "web", user_id: UserInfo?.id, role_id: UserInfo?.role_id?.[0] || 0
        };
        let mutation = SAVE_CUSTOMER_VEHICLE_DETAILS,
            variables = { vehicleDetailsInput: reqBody, ...api_user_role };

        executeGraphQLMutation(mutation, variables, client)
            .then((resp) => {
                let response = resp?.data?.save_customer_vehicle_details || null;
                if (response && !response.success) {
                    throw new Error(response.error || 'Something went wrong');
                }
                toast.success('Success');
                dispatch(getLoanDetail(lead_id, client));
                navigateToNext();
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };


    const navigateToNext = () => {
        if (lead?.leadDetail.is_lead_exception.toString() === '1') {
            navigate(`/lead-detail/customer-details/exceptional/${lead_id}`);
        } else if (lead?.leadDetail?.status_id !== 1) {
            navigate(`/lead-detail/sent-to-agent/${lead_id}`);
        }
    };

    let VehicleDetailsForm = useMemo(() => {
        return FORM_FIELDS.map((field) => {
            return field;
        });
    }, []);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
        // return leadHistory?.filter((v) => [4].includes(v.status_id))?.length || lead?.leadDetail?.doc_status === '0' ? true : false;
        return leadHistory?.filter((v) => [LEAD_STATUS.SENT_TO_AGENT].includes(v.status_id))?.length ? true : false || (lead?.leadDetail?.status_id === LEAD_STATUS.EXCEPTIONAL_CASE_PENDING_AT_TL);
    }, [leadHistory]);

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add('overflow-hidden');
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove('overflow-hidden');
    };

    const toggleOrExpiryConfirmPopup = () => {
        !orExpiryConfirm ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
        setOrExpiryConfirm(!orExpiryConfirm);
    };

    const sectionInfo = { section: 'Customer details', sub_section: 'Vehicle details' };
    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading d-flex">
                        <h2>Vehicle Details</h2>
                        <button
                            className='btn-line btn-exceptional'
                            onClick={showModalExceptionalcase}
                            disabled={![LEAD_STATUS.APPLICATION_CREATED, LEAD_STATUS.DOCUMENTS_UPLOADED, LEAD_STATUS.EXCEPTION_CASE_REJECTED_BY_TL].includes(lead.leadDetail?.status_id) ||
                                (lead?.leadDetail.is_lead_exception.toString() === '1' && lead.leadDetail?.status_id !== LEAD_STATUS.EXCEPTION_CASE_REJECTED_BY_TL) || isLeadAssigned ||
                                (lead?.leadDetail.doc_status === DOC_STATUS_MAP.APPROVED)
                            }
                        >
                            Exceptional Case
                        </button>
                    </div>
                    <Formsy
                        className="lead-form-filed"
                        autoComplete="off"
                        onValid={() => setAllowSubmit(true)}
                        onInvalid={() => setAllowSubmit(false)}
                        onValidSubmit={handleSubmit}
                        aria-label="lead form field basic"
                    >
                        <div className="row">
                            {VehicleDetailsForm.map((field) =>
                            (((field?.dependentOn && field?.dependentValue.includes(vehicleDetails[field?.dependentOn])) || !field?.dependentOn) && ['text', 'pattern-format'].includes(field.type) ? (
                                <fieldset class="form-filed col-md-6 ">
                                    <FormsyInputField
                                        id={field.id}
                                        name={field.name}
                                        type={field.type}
                                        value={(vehicleDetails && vehicleDetails[field.name]) || ""}
                                        placeholder=" "
                                        label={field.label}
                                        onChange={
                                            field.type === 'pattern-format'
                                                ? (data) => handlePatternFormatChange(data, field.name)
                                                : handleInputChange
                                        }
                                        format={field.ApplyNumberformat ? field.format : ''}
                                        validations={field.validations}
                                        validationError={vehicleDetails[field.name] ? field.validationError : ''}
                                        readOnly={lead?.leadDetail?.loan_id ? fieldsNeedToDisable(field?.name, existingVehicleDetails[field?.name], 'with_ucf') : field.readOnly}
                                        required={field.required}
                                        showAsterisk={field.showAsterisk}
                                    />
                                </fieldset>
                            ) : field.type === 'date-picker' ? (
                                <fieldset class="form-filed col-md-6 ">
                                    <FormsyDatePicker
                                        name={field.name}
                                        selectedDate={
                                            (vehicleDetails &&
                                                vehicleDetails[field.name] &&
                                                new Date(vehicleDetails[field.name])) ||
                                            ''
                                        }
                                        placeholder={field.label}
                                        minDate={field?.minDate ? new Date(field?.minDate) : new Date(new Date().getFullYear() - 1, 0, 1)}
                                        maxDate={new Date().setFullYear(new Date().getFullYear() + 3)}
                                        value={
                                            (vehicleDetails?.[field.name] &&
                                                new Date(vehicleDetails[field.name])) ||
                                            ''
                                        }
                                        onDateSelect={(date) => handleDateChange(date, field.name)}
                                        dateFormat="dd MMM, yyyy"
                                        validations={field.validations}
                                        validationError={vehicleDetails[field.name] ? field.validationError : ''}
                                        required={field.required}
                                        showAsterisk={field.showAsterisk}
                                        hasError={
                                            vehicleDetails.hasOwnProperty(field.name) && !vehicleDetails[field.name]
                                                ? true
                                                : false
                                        }
                                        readOnly={field.readOnly}
                                    />
                                </fieldset>
                            ) : field.type === 'dropdown' && !vehicleDetails[`${field.name}_hide_fields`] ? (
                                <fieldset class="single-select col-md-6">
                                    <FormsySelect
                                        name={field.name}
                                        id={field.id}
                                        inputProps={{
                                            options: allOptions?.[field?.optionsKey],
                                            placeholder: field.label,
                                            className: 'react-select',
                                            classNamePrefix: 'react-select',
                                            value: allOptions?.[field?.optionsKey]?.filter(
                                                ({ value }) => value?.toString() === vehicleDetails?.[field?.name]?.toString()
                                            ),
                                            isDisabled: lead?.leadDetail?.loan_id ? fieldsNeedToDisable(field?.name, existingVehicleDetails[field?.name], 'with_ucf') : field.readOnly,
                                        }}
                                        required={field.required}
                                        showAsterisk={field.showAsterisk}
                                        value={vehicleDetails?.[field?.name]}
                                        onChange={(data) =>
                                            ['make_id', 'model_id', 'vehicle_usage', 'vehicle_type_id', 'vehicle_category_id'].includes(field.name)
                                                ? handleMakeChange(field.name, data)
                                                : handleSelectChange(data, field.name)
                                        }
                                    />
                                </fieldset>
                            ) : field.type === 'textarea' ? (
                                <fieldset className="form-filed text-area-filed col-md-12">
                                    <div className="material">
                                        <FormsyTextAreaAutosize
                                            id={field.id}
                                            name={field.name}
                                            value={vehicleDetails && vehicleDetails[field.name]}
                                            placeholder=""
                                            label={field.label}
                                            onChange={handleInputChange}
                                            format={field.ApplyNumberformat ? field.format : ''}
                                            required={field.required}
                                            readOnly={field.readOnly}
                                            disabled={false}
                                        />
                                        <label data-label={field.label} className="form-label"></label>
                                    </div>
                                </fieldset>
                            ) : null
                            ))}
                        </div>

                        <div className="btn-save-remarks">
                            {isSubmitDisabled ||
                                !allowSubmit ||
                                getContext.markAsFreezed ||
                                accessCondition ||
                                isLeadAssigned ? (
                                <span className="span-disabled">Save & Next</span>
                            ) : (
                                <button aria-label="save naxt" type="submit" className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                            <button
                                aria-label="remarks history"
                                type="button"
                                className="btn-line"
                                onClick={showModalViewTimeLine}
                            >
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </Formsy>
                </div>
            </div>
            <div className="view-timeline-popup">
                <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                    <div className="modal-header">
                        <h2>Remark History</h2>
                    </div>
                    <LeadRemarkHistory
                        showremarkform={showremarkform}
                        markAsFreezed={getContext.markAsFreezed}
                        accessCondition={accessCondition || isLeadAssigned}
                        sectionInfo={sectionInfo}
                    />
                </Modal>
            </div>
            <div className="view-timeline-popup">
                <Modal show={orExpiryConfirm} handleClose={toggleOrExpiryConfirmPopup}>
                    <div className="modal-header">
                        <p>Change in OR Expiry Date might lead to change in Service Type. Do you wish to continue ?</p>
                    </div>
                    <div className="btn-save-remarks">
                        <button aria-label="save naxt" className="btn-primary" onClick={() => saveVehicleDetails()}>Yes</button>
                        <span className="span-disabled" onClick={() => toggleOrExpiryConfirmPopup()}>Cancel</span>
                    </div>
                </Modal>
            </div>
            <div className="view-timeline-popup exception-popup">
                <Modal show={Exceptionalcase} handleClose={hideModalExceptionalcase}>
                    <div className="modal-header">
                        <h2>Exceptional Case</h2>
                        <span>You need Dealer/TL approval for exceptional cases</span>
                    </div>
                    <div className='modal-body'>
                        <AddExceptionalCase lead={lead} user_id={UserInfo?.id} role_id={UserInfo?.role_id?.[0] || 0} lead_id={lead_id} dealer_id={lead?.leadDetail?.dealer_id} hideModalExceptionalcase={hideModalExceptionalcase} />
                    </div>

                </Modal>
            </div>
        </div>
    );
};
export default VehicleDetailsForm;
