import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { ACCEPTED_BY_AGENT_YMD, AGENT_ACTION, replaceErrorMessage, TL_APPROVAL_ACTION } from "../../../config/constants";
import * as moment from 'moment';
import { APPROVE_AGENT, REJECT_ALL_AGENTS } from "../../../services/agent.ggl";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLoanDetail } from "../../../store/action/allAction";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { encode } from "../../../config/crypto";

const AgentCard = ({ agent }) => {
    return (
        <ul>
            <li>
                <label>Agent Charges</label>
                <span>₱ {agent?.agent_fee}</span>
            </li>
            <li>
                <label>Final Charges</label>
                <span>₱ {agent?.final_agent_fee}</span>
            </li>
            <li>
                <label>TAT in Days</label>
                <span>{agent?.agent_tat || 0} Days</span>
            </li>
            <li>
                <label>Final TAT</label>
                <span>{agent?.final_agent_tat || 0} Days</span>
            </li>
        </ul>
    );
};
const AgentViewAction = (props) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isNonApproveAgent, setIsNonApproveAgent] = useState(false);
    const { sendToAgentInput, tl_approval, lead, getContext, is_edit_access, is_super_admin } = props;
    const { userInfo } = useSelector(({ user }) => ({
        userInfo: user.user_information ? JSON.parse(user.user_information) || {} : {},
    }));

    const rejectAllAgents = async () => {
        const variables = {
            lead_id: lead?.leadDetail?.id || 0,
            user_id: userInfo?.id || 0,
            role_id: userInfo?.role_id?.[0] || 0
        };
        executeGraphQLMutation(REJECT_ALL_AGENTS(), variables, client)
            .then(async (resp) => {
                if (resp?.data?.reject_all_agents) {
                    toast.success(resp?.data?.reject_all_agents?.message);
                    navigate(`/lead-detail/sent-to-agent/${encode(lead?.leadDetail?.id || 0)}`);
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(getLoanDetail(variables?.lead_id, client));
                await getContext?.getLeadHistory(variables?.lead_id);
            })
            .catch((err) => {
                toast.error(replaceErrorMessage(err.toString() || ""));
            });
    };

    const acceptAgent = async (agent_id) => {
        const variables = {
            lead_id: lead?.leadDetail?.id || 0,
            agent_id,
            user_id: userInfo?.id || 0,
            role_id: userInfo?.role_id?.[0] || 0
        };
        executeGraphQLMutation(APPROVE_AGENT(), variables, client)
            .then(async (resp) => {
                if (resp?.data?.approve_agent) {
                    toast.success(resp?.data?.approve_agent?.message);
                } else {
                    toast.error('Something went wrong');
                }
                dispatch(getLoanDetail(variables?.lead_id, client));
                await getContext?.getLeadHistory(variables?.lead_id);
            })
            .catch((err) => {
                toast.error(replaceErrorMessage(err.toString() || ""));
            });
    };

    const agents = Object.keys(sendToAgentInput?.lead_agents || {});
    const { approvedAgents, pendingAgents, acceptedAgents } = agents?.reduce((result, agent_id) => {
        if (sendToAgentInput?.lead_agents?.[agent_id]?.tl_action === TL_APPROVAL_ACTION.approved) result.approvedAgents.push(sendToAgentInput?.lead_agents?.[agent_id] || {});
        if (sendToAgentInput?.lead_agents?.[agent_id]?.tl_action === TL_APPROVAL_ACTION.pending || !sendToAgentInput?.lead_agents?.[agent_id]?.tl_action) result.pendingAgents.push(sendToAgentInput?.lead_agents?.[agent_id] || {});
        if (sendToAgentInput?.lead_agents?.[agent_id]?.agent_action === AGENT_ACTION.accepted) result.acceptedAgents.push(sendToAgentInput?.lead_agents?.[agent_id] || {});
        return result;
    }, { approvedAgents: [], pendingAgents: [], acceptedAgents: [] });

    return tl_approval === TL_APPROVAL_ACTION.approved ? <>
        <div className='select-agent-outer'>
            <div className='select-agent-heading'>
                <h3>Approved Agent</h3>
            </div>
            <div className='agent-charges'>
                <div className='agnet-charges-txt'>
                    Standard Charges:  <label>₱ {sendToAgentInput?.standard_price || 0}</label>
                </div>
                <div className='agnet-charges-txt'>
                    Final Charges:  <label>₱ {sendToAgentInput?.final_standard_price || 0}</label>
                </div>
            </div>
            <div className='select-agent-list'>
                {approvedAgents?.map(agent => (

                    <div className='select-agent-card'>
                        <div className='tl-approve-bg'>
                            <i className='ic-check'></i>
                            Approved From TL
                        </div>
                        <span className='card-heading'>{agent?.label || agent?.agent_name}</span>
                        <AgentCard agent={agent || {}} />

                    </div>
                ))}
            </div>

            <div className='non-approve-tag' onClick={() => setIsNonApproveAgent(!isNonApproveAgent)}>
                Non Approved Agents
                <i className={isNonApproveAgent ? `ic-keyboard_arrow_up` : `ic-keyboard_arrow_down`}></i>
            </div>
            {isNonApproveAgent ?
                <div className='select-agent-list'>
                    {pendingAgents?.map(agent => (

                        <div className='select-agent-card'>
                            <span className='card-heading'>{agent?.label || agent?.agent_name}</span>
                            <AgentCard agent={agent || {}} />

                        </div>
                    ))}
                </div>
                : <></>}
        </div>
    </> : tl_approval === TL_APPROVAL_ACTION.pending ? <>
        <div className='select-agent-outer'>
            <div className='select-agent-heading'>
                <h3>{`${acceptedAgents?.length} Selected Agents`}</h3>
                {((is_edit_access && +lead.leadDetail.tl_assigned === +userInfo.id) || is_super_admin) ? <button className='btn-line btn-reject' onClick={() => rejectAllAgents()} disabled={getContext.markAsFreezed}>Reject All</button> : <></>}
            </div>
            <div className='agent-charges'>
                <div className='agnet-charges-txt'>
                    Standard Charges:  <label>₱ {sendToAgentInput?.standard_price || 0}</label>
                </div>
                <div className='agnet-charges-txt'>
                    Final Charges:  <label>₱ {sendToAgentInput?.final_standard_price || 0}</label>
                </div>
            </div>
            <div className='select-agent-list'>
                {acceptedAgents?.map(agent => (
                    <div className='select-agent-card'>
                        <span className='card-heading'>{agent?.label || agent?.agent_name}</span>
                        <AgentCard agent={agent || {}} />
                        {agent?.agent_action === AGENT_ACTION.accepted && agent?.agent_action_date ?
                            <p>Accepted by Agent Date & Time - {moment(agent?.agent_action_date)?.format(ACCEPTED_BY_AGENT_YMD)}</p> : <></>
                        }
                        {((is_edit_access && +lead.leadDetail.tl_assigned === +userInfo.id) || is_super_admin) ?
                            <button className='btn-line mark-aaproved-tag' onClick={() => acceptAgent(agent?.agent_id)} disabled={getContext.markAsFreezed}>
                                Mark Approved
                            </button>
                            : <></>
                        }
                    </div>
                ))}
            </div>
        </div >
    </> : <></>;
};
export default AgentViewAction;
