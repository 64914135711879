import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import {
    AgentEntryType,
    IS_USER_ACCESS_PAGE,
    PERMISSION_CONFIG,
    TL_APPROVAL_ACTION
} from '../../../config/constants';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import DocumentGallery from '../DocumentGallery';
import { SEND_TO_AGENT as FORM_FIELDS } from '../formFields/sendToAgentFormFields.json';
import AgentViewAction from './AgentViewAction';
const TLApproval = (props) => {
    const [isReadOnly] = useState(true);
    const [sendToAgentInput, setSendToAgentInput] = useState({});
    const { masterdata, serviceKeyValue, lead } = useSelector(({ masterdata, lead }) => ({
        masterdata,
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadFiles: lead && lead.leadDetail && (lead.leadDetail.leaddocs || []),
        serviceKeyValue: masterdata?.data?.service_type?.reduce((result, item) => {
            result[item?.id] = { standard_price: item?.standard_pirce };
            return result;
        }, {})
    }));
    const { is_edit_access, is_super_admin } = IS_USER_ACCESS_PAGE(PERMISSION_CONFIG.lead_agent_selection.label, PERMISSION_CONFIG.lead_agent_selection.approve_reject);
    const getContext = useOutletContext();
    const { docList: documentCategoryList } = getContext || {};
    useEffect(() => {
        const fetchData = async () => {
            await getSentToAgentData();
        };
        fetchData();
    }, [lead?.leadDetail]);

    const getSentToAgentData = async () => {
        let updatedValues = {};
        if (lead?.leadDetail && (!sendToAgentInput['service_type_id'] || !sendToAgentInput['agent_id'])) {
            updatedValues.service_type_id = lead.leadDetail?.service_type_id || 0;
            updatedValues.agent_id = lead.leadDetail?.agent_id || 0;
        }
        if (lead?.leadDetail?.lead_detail) {
            updatedValues = {
                ...updatedValues,
                ...((lead?.leadDetail?.lead_detail?.inbound_lt_office_id && { inbound_lt_office_id: `${lead?.leadDetail?.lead_detail?.inbound_lt_office_id}` }) || {}),
                ...((lead?.leadDetail?.lead_detail?.sent_to_agent_date && { sent_to_agent_date: `${lead?.leadDetail?.lead_detail?.sent_to_agent_date}` }) || {})
            };
        }
        if (lead?.leadDetail?.lead_agents) {
            updatedValues.standard_price = lead?.leadDetail?.lead_agents?.[0]?.standard_price || 0;
            updatedValues.final_standard_price = lead?.leadDetail?.lead_agents?.[0]?.final_standard_price || 0;
            const lead_agents = lead?.leadDetail?.lead_agents?.reduce((result, item) => {
                if (![AgentEntryType.Old, AgentEntryType.New].includes(item?.entry_type)) result[item?.agent_id] = item;
                return result;
            }, {});
            updatedValues.lead_agents = lead_agents;
        }
        setSendToAgentInput((currentValue) => ({
            ...currentValue,
            ...updatedValues
        }));
    };

    let ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        return options;
    }, [masterdata]);

    const handleChange = async (data, field_name) => {
        const newValues = {};
        if (data.value && field_name !== "sent_to_agent_date" && field_name) {
            newValues[field_name] = data.value;
        }

        if (data?.target?.id) {
            newValues[data?.target?.id] = data?.target?.value;
        }

        if (field_name === 'sent_to_agent_date' && data) {
            newValues[field_name] = data;
        }
        if (field_name === "inbound_lt_office_id") {
            newValues.agent_id = null;
        }
        setSendToAgentInput((currentValue) => ({
            ...currentValue,
            ...newValues,
        }));
    };

    let SendToAgentForm = useMemo(() => {
        return FORM_FIELDS.map((field) => {
            return field;
        });
    }, []);

    // All the conditions can be combined here for button enable/disable
    const isSubmitDisabled = useMemo(() => {
        return true;
    }, []);

    return (
        <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
            <div className="image-tab-panel">
                <DocumentGallery documentCategoryList={documentCategoryList} />
            </div>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>TL Approval</h2>
                    </div>
                    {lead?.leadDetail?.tl_approval === TL_APPROVAL_ACTION.pending ?
                        (is_edit_access || is_super_admin) ?
                            <div className="tl-waiting-box-outer select-agnet-tl-aprove-txt">
                                <h3><i class="ic-access_timequery_builderschedule"></i>Waiting for Your Approval on Selected Agent</h3>
                            </div> :
                            <div className="tl-waiting-box-outer">
                                <h3>Waiting for TL Approval.</h3>
                            </div>
                        : <></>}
                    <Formsy>
                        <div className="row p-lg-t">
                            {SendToAgentForm.map((field) =>
                                ['text', 'pattern-format'].includes(field.type) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <FormsyInputField
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            value={sendToAgentInput?.[field?.name] || ""}
                                            placeholder=" "
                                            label={field.label}
                                            onChange={handleChange}
                                            format={field.ApplyNumberformat ? field.format : ''}
                                            validations={field.validations}
                                            validationError={sendToAgentInput[field.name] ? field.validationError : ''}
                                            readOnly={isReadOnly || isSubmitDisabled}
                                            required={field?.required}
                                            showAsterisk={field.showAsterisk}
                                        />
                                    </fieldset>
                                ) : (field.type === 'dropdown' && ['agent_id'].includes(field?.name)) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <div className='sta-multi-select'>
                                            {(Object.keys(sendToAgentInput?.lead_agents || {})?.length > 1 && <p>Multiple Selected</p>) ||
                                                (Object.keys(sendToAgentInput?.lead_agents || {})?.length === 1 && <p>{sendToAgentInput?.lead_agents?.[Object.keys(sendToAgentInput?.lead_agents || {})?.[0]]?.label || sendToAgentInput?.lead_agents?.[Object.keys(sendToAgentInput?.lead_agents || {})?.[0]]?.agent_name || ""} </p>) ||
                                                <p>Select Agent</p>}
                                            <i className='ic-keyboard_arrow_down'></i>
                                        </div>
                                    </fieldset>
                                ) : (field.type === 'dropdown' && !['agent_id'].includes(field?.name)) ? (
                                    <fieldset class={`single-select ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <FormsySelect
                                            name={field.name}
                                            id={field.id}
                                            inputProps={{
                                                options: ALL_OPTIONS?.[field?.optionsKey] || [],
                                                placeholder: field.label,
                                                className: 'react-select',
                                                classNamePrefix: 'react-select',
                                                value: ALL_OPTIONS?.[field?.optionsKey]?.filter(
                                                    ({ value }) => value === sendToAgentInput[field.name],
                                                ) || "",
                                                isDisabled: isReadOnly || isSubmitDisabled,
                                            }}
                                            required={field?.required}
                                            showAsterisk={field?.showAsterisk}
                                            value={sendToAgentInput?.[field?.name] || ""}
                                            onChange={(data) => handleChange(data, field.name)}
                                        />
                                    </fieldset>
                                ) : ['date-picker'].includes(field?.type) ? (
                                    <fieldset class={`form-filed ${(field?.is_full_row && 'col-md-12') || 'col-md-6'}`}>
                                        <div className="material">
                                            <div className="datepicker send-to-agent-date-picker">
                                                <FormsyDatePicker
                                                    onDateSelect={(data) => handleChange(data, field?.name)}
                                                    placeholder={field?.label}
                                                    name={field.name}
                                                    selectedDate={(sendToAgentInput?.[field?.name] && new Date(sendToAgentInput?.[field?.name])) || ""}
                                                    value={(sendToAgentInput?.[field?.name] && new Date(sendToAgentInput?.[field?.name])) || ""}
                                                    minDate={new Date().setMonth(new Date().getMonth() - 2)}
                                                    maxDate={new Date().setMonth(new Date().getMonth() + 2)}
                                                    dateFormat="dd MMM, yyyy h:mm aa"
                                                    showTimeSelect={true}
                                                    validations={field?.validations}
                                                    validationError={sendToAgentInput[field?.name] ? field?.validationError : ''}
                                                    required={field?.required}
                                                    hasError={''}
                                                    clearDate={false}
                                                    readOnly={isReadOnly || isSubmitDisabled}
                                                />
                                            </div>
                                        </div>
                                    </fieldset>
                                ) : null,
                            )}
                        </div>
                    </Formsy>
                    {
                        (Object.keys(sendToAgentInput?.lead_agents || {})?.length ? <AgentViewAction sendToAgentInput={sendToAgentInput} serviceKeyValue={serviceKeyValue} tl_approval={lead?.leadDetail?.tl_approval} lead={lead} getContext={getContext} is_edit_access={is_edit_access} is_super_admin={is_super_admin} /> : <></>)
                    }
                </div>
            </div >
        </div >
    );
};
export default TLApproval;
