import DateFormate from 'dateformat';
import React from 'react';
import AgentChange from './in-process/AgentChange';
import { STATUS_DETAILS_FORM } from './formFields/StatusDetailsFormFields.json';
import Select from 'react-select';
import { AgentEntryType } from '../../config/constants';

const StatusDetails = ({ paymentStatusDetails, lto_offices, lead, saveAgentLTOInput, setSaveAgentLTOInput, agentList, allAgentList, handleSelectChange, getContext, accessCondition }) => {
    let old_lead_agents = (lead?.leadDetail?.lead_agents?.length && lead?.leadDetail?.lead_agents?.find(item => [AgentEntryType.Old].includes(item?.entry_type))) || {};
    old_lead_agents = JSON.parse(JSON.stringify(old_lead_agents));
    old_lead_agents.lt_office_name = lead?.leadDetail?.lead_detail?.prev_outbound_lto_office?.lt_office_name || "";
    let new_lead_agents = (lead?.leadDetail?.lead_agents?.length && lead?.leadDetail?.lead_agents?.find(item => [AgentEntryType.New].includes(item?.entry_type))) || {};
    new_lead_agents = JSON.parse(JSON.stringify(new_lead_agents));
    new_lead_agents.lt_office_name = lead?.leadDetail?.lead_detail?.outbound_lto_office?.lt_office_name || "";

    return (<>
        <div className='changes-agent-card m-minus-30'>
            {lead?.leadDetail?.is_agent_change ? <AgentChange lead={lead} old_lead_agents={old_lead_agents} new_lead_agents={new_lead_agents} /> : STATUS_DETAILS_FORM?.map(field => (
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            options={lto_offices}
                            placeholder={field?.label || ""}
                            className="react-select"
                            classNamePrefix="react-select"
                            value={lto_offices?.filter(({ value }) => +value === (saveAgentLTOInput?.outbound_lt_office_id || lead?.leadDetail?.lead_detail?.outbound_lt_office_id))}
                            onChange={(e) => handleSelectChange(e, field?.name, true)}
                            aria-label={field?.label || ""}
                            isDisabled={getContext.markAsFreezed || accessCondition || lead?.leadDetail?.lead_detail?.outbound_lt_office_id || false}
                        />
                    </div>
                </fieldset>))}
        </div>
        <table className="">
            {(paymentStatusDetails?.length && (
                <tbody>
                    {paymentStatusDetails &&
                        paymentStatusDetails.map((timelinedata, index) => (
                            <tr className="p-relative" key={index}>
                                <td>
                                    <span className="status-list-heading">
                                        {timelinedata.title}  {(timelinedata?.is_completed && <span className='completed'>Completed</span>) || ""} {(timelinedata?.is_processing && <span className='processing'>Processing</span>) || ""} {(timelinedata?.is_delayed && <span className='delayed'>Delayed</span>) || ""}
                                    </span>
                                    {timelinedata?.status_stage_id ? <><span>{`Current Stage : ${timelinedata?.current_stage || ""}`}</span> </> : null}
                                    {timelinedata?.expected_completion_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {`Expected Completion Date : ${DateFormate(
                                                    timelinedata?.expected_completion_date,
                                                    'dd mmm, yyyy',
                                                )}`}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.followup_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {timelinedata?.followup_date
                                                    ? 'Next Follow-up : ' +
                                                    DateFormate(
                                                        timelinedata.followup_date,
                                                        'dd mmm, yyyy',
                                                    )
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.remark ? (
                                        <>
                                            <br></br>
                                            <span>
                                                {timelinedata?.remark
                                                    ? 'Comments : ' + timelinedata.remark
                                                    : null}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.start_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {`Start Date : ${DateFormate(
                                                    timelinedata?.start_date,
                                                    'dd mmm, yyyy',
                                                )}`}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.completion_date ? (
                                        <>
                                            <br />
                                            <span>
                                                {`Completion Date : ${DateFormate(
                                                    timelinedata?.completion_date,
                                                    'dd mmm, yyyy',
                                                )}`}
                                            </span>
                                        </>
                                    ) : null}
                                    {timelinedata?.doc_path ? (
                                        <>
                                            <br></br>
                                            <span>
                                                <a href={timelinedata?.doc_path} target="_blank" rel="noreferrer">
                                                    {'View Documents'}
                                                </a>
                                            </span>
                                        </>
                                    ) : null}
                                </td>
                                <td>{DateFormate(timelinedata.created_date, 'dd mmm, yyyy')}</td>
                            </tr>
                        ))}
                </tbody>
            )) || <span>No Record Found</span>}
        </table>
    </>
    );
};

export default StatusDetails;
