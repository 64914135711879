import { gql, useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import { default as React, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import ImportFile from '../../common/ImportFIle';
import { PAYMENT_TYPE, TITLEBOX_PATH, getKeysDataIfExist } from '../../config/constants';
import GeneralService from '../../services/generalService';
import { getLoanDetail } from '../../store/action/allAction';
import FormsyDatePicker from '../elements/FormsyDatePicker';
import FormsyInputField from '../elements/FormsyInputField';
import FormsySelect from '../elements/FormsySelect';
import Loader from '../elements/Loader';
import { AddStatusDetail } from './formFields/AddStatusDetail.js';
import * as moment from 'moment';
const { ADD_STATUS, ADD_PAYMENT_DATE_TYPE, REMARK, ADD_PAYMENT_RADIO_OPTIONS } = AddStatusDetail;

const AddStatusPaymentDetails = ({ lead, param, user_id, paymentStatusDetails }) => {
    const [paymentStatusData, setPaymentStatusData] = useState({});
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageName, setImageName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let headerName = param === 'status-details' ? 'Add Status' : 'Add Payment Record';
    const client = useApolloClient();
    const dispatch = useDispatch();

    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    const { masterdata } = useSelector(({ masterdata }) => ({ masterdata }));

    const handleToUploadLogo = async (event) => {
        let file = event.target.files[0];
        var doc_path = '';
        if (file) {
            const formData = new FormData();
            formData.append('upload_type', TITLEBOX_PATH);
            formData.append('lead_id', lead?.leadDetail?.id);
            formData.append('images', file);

            try {
                const publicUrl = await GeneralService.uploadFilesToS3(formData, {
                    'Content-Type': 'multipart/form-data',
                });
                doc_path =
                    publicUrl &&
                    publicUrl.data &&
                    publicUrl.data.data &&
                    publicUrl.data.data.length &&
                    (publicUrl.data.data[0].file_url || '');
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        setImagePreview(doc_path);
        setImageName(file?.name);
    };

    const handleInputChange = (event) => {
        if (event.target.id) {
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                [event.target.id]: event.target.value,
            }));
        }
    };

    const handleDateChange = (date, field_name) => {
        if (date && field_name) {
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                [field_name]: date,
            }));
        }
    };

    const handlePatternFormatChange = (data, field_name) => {
        if (field_name && data.value !== undefined) {
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                [field_name]: data.value,
            }));
        }
    };
    const handleRadioChange = event => {
        const tempPaymentStatusData = { ...paymentStatusData };
        if (event.target.name && event.target.value) {
            if ([PAYMENT_TYPE.paid].includes(event.target.value)) {
                delete tempPaymentStatusData?.payment_received_date;
            }
            else if ([PAYMENT_TYPE.received].includes(event.target.value)) {
                delete tempPaymentStatusData?.payment_request_date;
                delete tempPaymentStatusData?.payment_paid_date;
            }
            setPaymentStatusData({
                ...tempPaymentStatusData,
                [event.target.name]: event.target.value
            });
        }

    };

    let isRadioMarked = (param === 'status-details' ? false : !paymentStatusData?.payment_type);

    const onSubmit = async (e) => {
        let variables, mutation;
        e.preventDefault();
        if (!Object.keys(paymentStatusData)?.length) {
            toast.error('Please enter data');
            return;
        }
        setIsLoading(true);

        const lead_id = lead?.leadDetail?.id || 0;
        if (param === 'status-details') {
            const KeysToFetch = ["remark", "followup_date"];
            if (!paymentStatusData?.title && paymentStatusData?.status_stage_id) {
                KeysToFetch.push(["stage_name", "title"], "status_stage_id", "start_date", "expected_completion_date", "completion_date");
            }
            else if (paymentStatusData?.title && paymentStatusData?.status_stage_id) {
                KeysToFetch.push("title", "status_stage_id", "start_date", "expected_completion_date", "completion_date");

            }
            else if (paymentStatusData?.title && !paymentStatusData?.status_stage_id) {
                KeysToFetch.push("title");
            }
            if (paymentStatusData?.is_in_progress_completed === "Yes") {
                KeysToFetch.push("in_progress_stage_id", "in_progress_completion_date");
            }
            variables = {
                statusPaymentInput: {
                    lead_id: lead?.leadDetail?.id,
                    upload_proof: imagePreview || null,
                    created_by: user_id,
                    history_type: 1,
                    ...getKeysDataIfExist(paymentStatusData, KeysToFetch)
                },
            };
        } else {
            variables = {
                statusPaymentInput: {
                    lead_id: lead?.leadDetail?.id,
                    upload_proof: imagePreview || null,
                    created_by: user_id,
                    history_type: 2,
                    payment_amount: Number(paymentStatusData?.payment_amount) || '',
                    payment_type: paymentStatusData?.payment_type || '',
                    ...getKeysDataIfExist(paymentStatusData, ["payment_request_date", "payment_paid_date", "payment_received_date", "remark"])
                }
            };
        }
        variables = { ...variables, user_id: UserInfo?.id || 0 };
        mutation = gql`
            mutation saveStatusPaymentDetails($statusPaymentInput: StatusPaymentInput!, $user_id: Float!) {
                save_status_payment_details(statusPaymentInput: $statusPaymentInput, user_id: $user_id) {
                    success
                    error
                }
            }
        `;
        try {
            await executeGraphQLMutation(mutation, variables, client)
                .then((resp) => {
                    let response = resp?.data?.save_status_payment_details || null;
                    if (response && !response.success) {
                        throw new Error(response.error || 'Something went wrong');
                    }
                    toast.success('Success');
                    dispatch(getLoanDetail(lead_id, client));
                    window.location.reload();
                    // setIsLoading(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setIsLoading(false);
                });
        } catch (error) {
            toast.error('Failed');
            console.error(error);
            setIsLoading(false);
        }
    };

    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            let newFields = { [field_name]: +data.value };
            if (field_name === "status_stage_id") {
                newFields = {
                    ...newFields, ...getKeysDataIfExist(data, [["label", "stage_name"], "complete_label", "start_label"])
                };
            }
            if (field_name === "in_progress_stage_id") {
                newFields = {
                    ...newFields, in_progress_completion_date: null
                };
            }
            setPaymentStatusData((currentValue) => ({
                ...currentValue,
                ...newFields
            }));
        }
    };

    let ALL_OPTIONS = useMemo(() => {
        let options = masterdata ? { ...masterdata.data } : {};
        let { in_progress_stages_option, all_stages, in_progress_stages_details } = paymentStatusDetails?.reduce((result, data) => {
            if (data.status_stage_id > 0) {
                result.all_stages.push(+data?.status_stage_id);
            }
            if (data.status_stage_id > 0 && !data?.completion_date) {
                result.in_progress_stages_option.push(+data?.status_stage_id);
                result.in_progress_stages_details[data?.status_stage_id] = { start_date: data?.start_date };
            }
            return result;
        }, { in_progress_stages_option: [], all_stages: [], in_progress_stages_details: {} });
        options.status_update_stages = masterdata?.data?.status_update_stages?.filter(item => !all_stages?.includes(item?.id) && item?.service_ids?.includes(lead?.leadDetail?.service_type_id)) || [];
        options.in_progress_stages_option = masterdata?.data?.status_update_stages?.filter(item => in_progress_stages_option?.includes(item?.id)) || [];
        options.in_progress_stages_details = in_progress_stages_details || {};
        setPaymentStatusData(prev => ({ ...prev, is_in_progress_present: in_progress_stages_option?.length ? true : false }));
        return options;
    }, [masterdata, paymentStatusDetails?.length, lead?.leadDetail?.service_type_id]);

    return (
        <div>
            {isLoading ? <Loader /> : null}
            <div className="modal-header">
                <h2>{headerName}</h2>
            </div>

            <div className="modal-body">
                <form>
                    <div className="">
                        <>
                            <Formsy
                                className="lead-form-filed"
                                autoComplete="off"
                                onValid={() => setAllowSubmit(true)}
                                onInvalid={() => setAllowSubmit(false)}
                                onValidSubmit={onSubmit}
                                aria-label="lead form field basic"
                            >
                                {param === 'status-details' ? (
                                    <div className="row">
                                        {ADD_STATUS?.map((field) => {
                                            if (!((field?.dependentOn && field?.dependentValue.includes(paymentStatusData[field?.dependentOn])) || !field?.dependentOn)) {
                                                return null;
                                            }
                                            if (['text', 'pattern-format'].includes(field.type)) {
                                                return (
                                                    <>
                                                        {field?.name === "title" && [true]?.includes(paymentStatusData?.is_in_progress_present) ? <div className={`col-md-12 p-lg-t p-lg-b`}>
                                                            <label className="form-label">Next Stage</label>
                                                        </div> : <></>}
                                                        <fieldset className={`form-filed ${field?.is_full_row ? 'col-md-12' : 'col-md-6'} m-lg-b`}>
                                                            <FormsyInputField
                                                                id={field.id}
                                                                name={field.name}
                                                                type={field.type}
                                                                value={(paymentStatusData && paymentStatusData[field.name]) || ""}
                                                                placeholder=" "
                                                                label={field.label}
                                                                onChange={
                                                                    field.type === 'pattern-format'
                                                                        ? (data) => handlePatternFormatChange(data, field.name)
                                                                        : handleInputChange
                                                                }
                                                                format={field.ApplyNumberformat ? field.format : ''}
                                                                validations={field.validations}
                                                                validationError={paymentStatusData[field.name] ? field.validationError : ''}
                                                                readOnly={field.readOnly}
                                                                required={!["title"]?.includes(field?.name) ? field.required : ["Yes"]?.includes(paymentStatusData?.is_in_progress_completed) ? false : paymentStatusData?.status_stage_id ? false : field.required}
                                                                showAsterisk={!["title"]?.includes(field?.name) ? field.required : ["Yes"]?.includes(paymentStatusData?.is_in_progress_completed) ? false : paymentStatusData?.status_stage_id ? false : field.required}
                                                            />
                                                        </fieldset>
                                                    </>
                                                );
                                            }
                                            if (['date-picker'].includes(field.type)) {
                                                return (
                                                    <fieldset className={`form-filed ${field?.is_full_row ? 'col-md-12' : 'col-md-6'} m-lg-b`}>
                                                        <FormsyDatePicker
                                                            name={field.name}
                                                            selectedDate={
                                                                (paymentStatusData &&
                                                                    paymentStatusData[field.name] &&
                                                                    new Date(paymentStatusData[field.name])) ||
                                                                ''
                                                            }
                                                            placeholder={field.label}
                                                            minDate={["completion_date", "expected_completion_date"].includes(field?.name) && paymentStatusData?.start_date ? new Date(moment(paymentStatusData?.start_date)) : ["in_progress_completion_date"].includes(field?.name) ? new Date(moment(ALL_OPTIONS?.in_progress_stages_details?.[paymentStatusData?.in_progress_stage_id]?.start_date)?.utc()?.startOf("day")) : new Date(new Date().getFullYear() - 1, 0, 1)}
                                                            maxDate={["start_date"].includes(field?.name) && paymentStatusData?.expected_completion_date ? new Date(moment(paymentStatusData?.expected_completion_date)) : new Date().setFullYear(new Date().getFullYear() + 3)}
                                                            value={
                                                                (paymentStatusData?.[field.name] &&
                                                                    new Date(paymentStatusData[field.name])) ||
                                                                ''
                                                            }
                                                            onDateSelect={(date) => handleDateChange(date, field.name)}
                                                            dateFormat="dd MMM, yyyy"
                                                            validations={field.validations}
                                                            validationError={paymentStatusData[field.name] ? field.validationError : ''}
                                                            required={["start_date", "expected_completion_date"].includes(field?.name) && paymentStatusData?.status_stage_id ? true : field?.required}
                                                            showAsterisk={["start_date", "expected_completion_date"].includes(field?.name) && paymentStatusData?.status_stage_id ? true : field?.showAsterisk}
                                                            hasError={
                                                                paymentStatusData.hasOwnProperty(field.name) && !paymentStatusData[field.name]
                                                                    ? true
                                                                    : false
                                                            }
                                                            readOnly={["in_progress_completion_date"].includes(field?.name) && !paymentStatusData?.in_progress_stage_id ? true : field.readOnly}
                                                        />
                                                    </fieldset>
                                                );
                                            }
                                            if (['dropdown'].includes(field.type)) {
                                                return (
                                                    <fieldset className={`single-select ${field?.is_full_row ? 'col-md-12' : 'col-md-6'} m-lg-b`}>
                                                        <FormsySelect
                                                            name={field.name}
                                                            id={field.id}
                                                            inputProps={{
                                                                options: ALL_OPTIONS?.[field?.optionsKey],
                                                                placeholder: field.label,
                                                                className: 'react-select',
                                                                classNamePrefix: 'react-select',
                                                                value: ALL_OPTIONS?.[field?.optionsKey]?.filter(
                                                                    ({ value }) => value?.toString() === paymentStatusData?.[field?.name]?.toString()
                                                                )
                                                            }}
                                                            value={paymentStatusData?.[field?.name]}
                                                            onChange={(data) => handleSelectChange(data, field.name)}
                                                            required={!["status_stage_id"]?.includes(field?.name) ? field.required : ["Yes"]?.includes(paymentStatusData?.is_in_progress_completed) ? false : paymentStatusData?.title ? false : field.required}
                                                            showAsterisk={!["status_stage_id"]?.includes(field?.name) ? field.required : ["Yes"]?.includes(paymentStatusData?.is_in_progress_completed) ? false : paymentStatusData?.title ? false : field.required}
                                                        />
                                                    </fieldset>
                                                );
                                            }
                                            if (['radio'].includes(field?.type)) {
                                                return (
                                                    <div className={`${(field?.is_full_row && 'col-md-12') || 'col-md-3'} p-lg-t`}>
                                                        <label className="form-label">{field?.label}</label>
                                                        <fieldset className={`form-filed radio-btn radio-type-txt p-xs-t`}>
                                                            {field?.options.map((option) => (
                                                                <div className="custom-control custom-checkbox" key={option.value}>
                                                                    <input
                                                                        id={option.value}
                                                                        type="radio"
                                                                        className="custom-control-input"
                                                                        name={option.name}
                                                                        value={option.value}
                                                                        checked={option.value === paymentStatusData?.[option.name] ? true : false}
                                                                        onChange={handleRadioChange}
                                                                        disabled={field?.readOnly}
                                                                        aria-label="handle change radio"
                                                                        required={field?.required}
                                                                    />
                                                                    <label htmlFor={option.id} className="custom-control-label">{option.label}</label>
                                                                </div>
                                                            ))}
                                                        </fieldset>
                                                    </div>
                                                );
                                            }
                                            if (['labels'].includes(field.type)) {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className='add-stage-outer'>
                                                            <div className='add-stage-content'>
                                                                <label>
                                                                    {field?.label}
                                                                </label>
                                                                <span>
                                                                    {paymentStatusData?.[field?.name] || "stage_start_label"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // <div className={`form-filed ${field?.is_full_row ? 'col-md-12' : 'col-md-6'} m-lg-b`}>{field?.label}: <span>{paymentStatusData?.[field?.name] || "stage_start_label"}</span> </div>
                                                );
                                            }
                                            return null
                                        }
                                        )}
                                        <fieldset className="form-filed col-md-6 fin-logo-row">
                                            <div className="image-uploader-outer">
                                                <ImportFile name={imageName} onChooseFile={handleToUploadLogo} field_text={'Upload by dropping file here'} />
                                            </div>
                                        </fieldset>
                                    </div>
                                ) : (
                                    <div className="row">
                                        {ADD_PAYMENT_RADIO_OPTIONS?.map((field) =>
                                        (
                                            <fieldset className="form-filed col-md-12  m-lg-b radio-btn radio-type-txt">
                                                <label className="form-label">{field?.label}</label><br />
                                                {field.options.map((option) => (
                                                    <div className="custom-control custom-checkbox" key={option.value}>
                                                        <input
                                                            id={option.value}
                                                            type="radio"
                                                            className="custom-control-input"
                                                            name={option.name}
                                                            value={option.value}
                                                            onChange={handleRadioChange}
                                                            aria-label={`for ${option.name}`}
                                                            required={true}
                                                        />
                                                        <label htmlFor={option.id} className="custom-control-label">{option.label}</label>
                                                    </div>
                                                ))}
                                            </fieldset>)

                                        )}

                                        <fieldset class="form-filed col-md-6 m-lg-b add-payment-datpicker">
                                            <FormsyInputField
                                                id="payment_amount"
                                                name="payment_amount"
                                                type="number-format"
                                                value={paymentStatusData?.payment_amount || ''}
                                                placeholder=" "
                                                label="Payment Amount"
                                                onChange={(data) => handlePatternFormatChange(data, 'payment_amount')}
                                                validations={null}
                                                validationError=""
                                                readOnly={false}
                                                required={true}
                                                showAsterisk={true}
                                            />
                                        </fieldset>

                                        {ADD_PAYMENT_DATE_TYPE?.map(field => (
                                            field?.dependentValue?.includes(paymentStatusData?.[field?.dependentOn]) &&
                                            <fieldset class="form-filed col-md-6 m-lg-b datepicker-filed add-payment-datpicker">
                                                <FormsyDatePicker
                                                    name={field?.name}
                                                    selectedDate={
                                                        (paymentStatusData?.[field?.name] &&
                                                            new Date(paymentStatusData?.[field?.name])) ||
                                                        ''
                                                    }
                                                    placeholder={field?.placeholder}
                                                    minDate={new Date().setMonth(new Date().getMonth() - 3)}
                                                    maxDate={new Date().setFullYear(new Date().getFullYear() + 3)}
                                                    value={
                                                        (paymentStatusData?.[field?.name] &&
                                                            new Date(paymentStatusData?.[field?.name])) ||
                                                        ''
                                                    }
                                                    dateFormat={field?.dateFormat}
                                                    onDateSelect={(date) => handleDateChange(date, field?.name)}
                                                    validations={null}
                                                    validationError=""
                                                    required={true}
                                                    readOnly={false}
                                                />
                                            </fieldset>
                                        ))}
                                        {REMARK?.map(field => (
                                            <fieldset class={`form-filed col-md-12 m-lg-b`}>
                                                <FormsyInputField
                                                    id={field.id}
                                                    name={field.name}
                                                    type={field.type}
                                                    value={paymentStatusData?.[field?.name] || ""}
                                                    placeholder=" "
                                                    label={field.label}
                                                    onChange={handleInputChange}
                                                    format={field.ApplyNumberformat ? field.format : ''}
                                                    validations={field.validations}
                                                    validationError={paymentStatusData[field.name] ? field.validationError : ''}
                                                    // readOnly={isReadOnly || isSubmitDisabled}
                                                    required={field?.required}
                                                    showAsterisk={field.showAsterisk}
                                                />
                                            </fieldset>
                                        ))}

                                        <fieldset className="form-filed col-md-12 fin-logo-row">
                                            <div className="image-uploader-outer">
                                                <ImportFile name={imageName} onChooseFile={handleToUploadLogo} field_text={'Upload by dropping file here'} />
                                            </div>
                                        </fieldset>
                                    </div>
                                )}
                                <div className="btn-save-remarks">
                                    <button
                                        aria-label="save naxt"
                                        onClick={onSubmit}
                                        className="btn-primary"
                                        disabled={!allowSubmit || isRadioMarked}
                                    >
                                        {headerName}
                                    </button>
                                </div>
                            </Formsy>
                        </>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddStatusPaymentDetails;
