import { useApolloClient } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { MARK_RATING } from "../../services/customer.ggl";


const ReviewRating = ({ leadDetails, setIsLoading }) => {
    const ratingRange = [1, 2, 3, 4, 5];
    const [ratingArray, setRatingArray] = useState(ratingRange);
    const [submitDone, setSubmitDone] = useState(false);
    const [ratingDetail, setRatingDetail] = useState({ ...leadDetails?.lead_rating, lead_id: leadDetails?.id || 0 });
    const client = useApolloClient();

    const handleSubmit = (action) => {
        let variables = {
            rate_input: {
                ...ratingDetail
            },
        };
        setIsLoading(true);
        executeGraphQLMutation(MARK_RATING, variables, client)
            .then((resp) => {
                if (resp?.data?.mark_rating) {
                    toast.success(resp?.data?.mark_rating?.message);
                    setSubmitDone(true);
                } else {
                    toast.error('Something went wrong');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(_ => setIsLoading(false));
    };

    const handleRatingSelect = (val) => {
        setRatingDetail(prev => ({ ...prev, rating: +val }));
        setRatingArray([...ratingRange]);
    };

    const changeHandler = (key, e) => {
        setRatingDetail(prev => ({ ...prev, [key]: e.target.value }));
    };

    const isSubmitDisable = useMemo(() => {
        return (!ratingDetail?.lead_id || !ratingDetail?.rating || (ratingDetail?.rating <= 3 && !ratingDetail?.remark));
    }, [ratingDetail]);
    return (
        <React.Fragment>
            <div className="review-rating-outer">
                <h2>Share Your Experience</h2>
                <span>Rate your experience about title transfer</span>
                <div className="rating-start">
                    {ratingArray?.map((rate) => (
                        <i className={`${(rate <= ratingDetail?.rating && 'ic-star-filled') || 'ic-star-empty'}`} onClick={() => handleRatingSelect(rate)}></i>
                    ))}
                </div>
                <fieldset class="form-filed">
                    <div class="material">
                        <textarea class="form-input" placeholder=" " rows="3" onChange={(e) => changeHandler('remark', e)}
                            value={ratingDetail?.remark || ''} aria-label="remark"></textarea>
                        <label data-label="Write Comments" class="form-label">
                        </label>
                    </div>
                </fieldset>
                {((isSubmitDisable || submitDone) && (
                    <div className="btn-submit t-center">
                        <span className="span-disabled">Submit</span>
                    </div>
                )) || (<div className="btn-submit t-center">
                    <button className="btn-primary" onClick={handleSubmit}>Submit</button>
                </div>)}

            </div>
        </React.Fragment>
    );
};

export default ReviewRating;