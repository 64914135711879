import React, { useEffect, useState } from 'react';
import Pagination from '../elements/pagination';
import Loader from '../elements/Loader';
import OfficePriceTypeView from './OfficePriceTypeView';
import { IS_USER_ACCESS_PAGE, PAYMENT_METHOD_OPTIONS } from "../../config/constants";


const AgentListTable = ({ agents, isLoading, onPaginate, totalPage, currentPage, showModalAgentOnboard
    , setAgentId, showModalAgentDocs }) => {
    const [agentList, setAgentList] = useState([]);

    useEffect(() => {
        setAgentList(agents);
    }, [agents]);

    const updateAgentDetail = (id) => {
        setAgentId(id);
        showModalAgentOnboard();
    };

    const showAgentDocs = (id) => {
        setAgentId(id);
        showModalAgentDocs();
    };

    return (
        <div className="agent-detail-table">
            <table>
                <thead>
                    <tr>
                        <th>Agent Name</th>
                        <th>Mobile Number</th>
                        <th>Bank Name</th>
                        <th colSpan='3'>
                            <table className='sub-table'>
                                <thead>
                                    <tr>
                                        <th>LTO Region</th>
                                        <th>
                                            <table className='sub-table-sub-data'>
                                                <tr>
                                                    <th>Service Type</th>
                                                    <th>Price</th>
                                                </tr>
                                            </table>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {agentList?.map((agent, index) => {

                        return (
                            <tr key={agent.id}>
                                <td>
                                    {agent?.agent_name || '-'}
                                </td>
                                <td>{agent?.agent_mobile || '-'}</td>
                                <td>
                                    <ul>
                                        <li>
                                            {(agent?.payment_method === PAYMENT_METHOD_OPTIONS?.[0]?.value && agent?.bank_name?.name) || (agent?.payment_method === PAYMENT_METHOD_OPTIONS?.[1]?.value && PAYMENT_METHOD_OPTIONS?.[1]?.label) || '-'}
                                        </li>
                                        {agent?.payment_method === PAYMENT_METHOD_OPTIONS?.[0]?.value && <>
                                            <li>
                                                {`Branch:- ${agent?.branch_name}` || '-'}
                                            </li>
                                            <li>
                                                {`A/c No:- ${agent?.account_number}` || '-'}
                                            </li>
                                            <li>
                                                {`Beneficiary:- ${agent?.beneficiary_name}` || '-'}
                                            </li>
                                        </>}
                                    </ul>
                                </td>
                                <td colSpan={3}>
                                    {<OfficePriceTypeView lto_office_agent={agent?.lto_office_agent} />}
                                </td>
                                <td>{(+agent?.status === 1 && "Active") || (+agent?.status === 0 && "Inactive") || '-'}</td>
                                <td>
                                    <ul className="action-btn">
                                        {IS_USER_ACCESS_PAGE('agent_onboarding', 'agent_onboarding').is_edit_access && (<li onClick={() =>
                                            updateAgentDetail(agent.id)
                                        }>
                                            <i className="ic-createmode_editedit"></i>
                                        </li>)}
                                        <li
                                            onClick={() => {
                                                showAgentDocs(agent.id);
                                            }}
                                        >
                                            <i className="ic-application"></i>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <p className="align-center-no-record">
                {agentList?.length === 0 && 'No record found'}
            </p>

            {isLoading ? <Loader /> : null}
            <Pagination
                pageCount={totalPage}
                activePage={currentPage || 1}
                onPaginate={onPaginate}
            />
        </div>
    );
};
export default AgentListTable;
