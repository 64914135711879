import FormsyInputField from "../../elements/FormsyInputField";

const AgentServiceTatViewAction = (props) => {
    const { sendToAgentInput, handleCopy, handleAgentInputChange, isFinalFieldsDisabled, statusTrackingUrl } = props;
    const leadAgents = Object.keys(sendToAgentInput?.lead_agents || {});
    return (leadAgents?.length > 1 && <>
        <div className="acceptance-link">
            <div className="link-name-url">
                <span className="link-name">End User Link</span>
                <span class="link-url"> {statusTrackingUrl || ""} </span>
            </div>
            <div className="url-btn">
                <span className="button" onClick={_ => handleCopy(statusTrackingUrl)} disabled={!statusTrackingUrl}>
                    Copy URL
                </span>
            </div>
        </div>
        <div className="multi-agent-listing">
            <div className="select-agent-heading select-single-agnet-heading"><h3>{`${Object.keys(sendToAgentInput?.lead_agents || {})?.length} Selected Agents`}</h3></div>

            <div className="agent-charge-box single-agnet-charge-box">
                <div className="agent_charges">
                    <span className="label"> Standard Charges:</span>
                    <label className="value">{`₱${sendToAgentInput?.standard_price || 0}`}</label>
                </div>
                <div className="agent_charges final_charges_input">
                    <span className="label">Final Charges:</span>

                    <FormsyInputField
                        id={"final_standard_price"}
                        name={"final_standard_price"}
                        type={"number-format"}
                        value={sendToAgentInput?.final_standard_price || ""}
                        onChange={(e) => handleAgentInputChange(e)}
                        label={""}
                        readOnly={isFinalFieldsDisabled}
                        validations={'isNegativeValue'}
                    />

                </div>
            </div>

            {leadAgents?.map(agent_id => (
                <>

                    <div className="select-agent-heading select-single-agnet-heading">
                        <h3>{sendToAgentInput?.lead_agents?.[agent_id]?.label || sendToAgentInput?.lead_agents?.[agent_id]?.agent_name} </h3>

                    </div>
                    <div className="agent-charge-box">
                        <div className="agent_charges">
                            <span className="label">Agent Charges</span>
                            <label className="value">₱{sendToAgentInput?.lead_agents?.[agent_id]?.agent_fee} </label>
                        </div>
                        <div className="agent_charges final_charges_input">
                            <span className="label">Final Charges</span>
                            <FormsyInputField
                                id={"final_agent_fee"}
                                name={"final_agent_fee"}
                                type={"number-format"}
                                value={sendToAgentInput?.lead_agents?.[agent_id]?.final_agent_fee || ""}
                                onChange={(e) => handleAgentInputChange(e, agent_id)}
                                label={""}
                                // readOnly={!sendToAgentInput?.lead_agents?.[agent_id]?.agent_fee || isSubmitDisabled}
                                readOnly={isFinalFieldsDisabled}
                                validations={'isNegativeValue'}
                            />
                        </div>
                        <div className="agent_charges">
                            <span className="label">TAT in Days:</span>
                            <label className="value">{sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat ? `${sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat} Days` : ``} </label>
                        </div>
                        <div className="agent_charges final_charges_input">
                            <span className="label">Final TAT</span>
                            <FormsyInputField
                                id={"final_agent_tat"}
                                name={"final_agent_tat"}
                                type={"number-format"}
                                value={sendToAgentInput?.lead_agents?.[agent_id]?.final_agent_tat || ""}
                                onChange={(e) => handleAgentInputChange(e, agent_id)}
                                label={""}
                                // readOnly={!sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat || isSubmitDisabled}
                                readOnly={isFinalFieldsDisabled}
                                validations={'isNegativeValue'}
                            />
                        </div>
                    </div>

                    <div className="acceptance-link">
                        <div className="link-name-url">
                            <span className="link-name">Acceptance Link</span>
                            <span class="link-url"> {sendToAgentInput?.lead_agents?.[agent_id]?.link} </span>
                        </div>
                        <div className="url-btn">
                            <span className="button" onClick={_ => handleCopy(sendToAgentInput?.lead_agents?.[agent_id]?.link)} disabled={!sendToAgentInput?.lead_agents?.[agent_id]?.link}>
                                Copy URL
                            </span>
                        </div>
                    </div>
                </>
            ))}
        </div>
    </>)
        || (leadAgents?.length === 1 && leadAgents?.map(agent_id => (
            <>
                <div className="agent-charge-box">
                    <div className="agent_charges">
                        <span className="label">Standard Charges</span>
                        <label className="value">₱{sendToAgentInput?.lead_agents?.[agent_id]?.standard_price} </label>
                    </div>
                    <div className="agent_charges final_charges_input">
                        <span className="label">Final Charges</span>
                        <FormsyInputField
                            id={"final_standard_price"}
                            name={"final_standard_price"}
                            type={"number-format"}
                            value={sendToAgentInput?.lead_agents?.[agent_id]?.final_standard_price || ""}
                            onChange={(e) => handleAgentInputChange(e, agent_id)}
                            label={""}
                            // readOnly={!sendToAgentInput?.lead_agents?.[agent_id]?.standard_price || isSubmitDisabled}
                            readOnly={isFinalFieldsDisabled}
                            validations={'isNegativeValue'}
                        />
                    </div>
                    <div className="agent_charges">
                        <span className="label">Agent Charges</span>
                        <label className="value">₱{sendToAgentInput?.lead_agents?.[agent_id]?.agent_fee} </label>
                    </div>
                    <div className="agent_charges final_charges_input">
                        <span className="label">Final Charges</span>
                        <FormsyInputField
                            id={"final_agent_fee"}
                            name={"final_agent_fee"}
                            type={"number-format"}
                            value={sendToAgentInput?.lead_agents?.[agent_id]?.final_agent_fee || ""}
                            onChange={(e) => handleAgentInputChange(e, agent_id)}
                            label={""}
                            // readOnly={!sendToAgentInput?.lead_agents?.[agent_id]?.agent_fee || isSubmitDisabled}
                            readOnly={isFinalFieldsDisabled}
                            validations={'isNegativeValue'}
                        />
                    </div>
                    <div className="agent_charges">
                        <span className="label">TAT in Days:</span>
                        <label className="value">{sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat ? `${sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat} Days` : ``} </label>
                    </div>
                    <div className="agent_charges final_charges_input">
                        <span className="label">Final TAT</span>
                        <FormsyInputField
                            id={"final_agent_tat"}
                            name={"final_agent_tat"}
                            type={"number-format"}
                            value={sendToAgentInput?.lead_agents?.[agent_id]?.final_agent_tat || ""}
                            onChange={(e) => handleAgentInputChange(e, agent_id)}
                            label={""}
                            // readOnly={!sendToAgentInput?.lead_agents?.[agent_id]?.agent_tat || isSubmitDisabled}
                            readOnly={isFinalFieldsDisabled}
                            validations={'isNegativeValue'}
                        />
                    </div>
                </div>

                <div className="acceptance-link">
                    <div className="link-name-url">
                        <span className="link-name">Acceptance Link</span>
                        <span class="link-url"> {sendToAgentInput?.lead_agents?.[agent_id]?.link} </span>
                    </div>
                    <div className="url-btn">
                        <span className="button" onClick={_ => handleCopy(sendToAgentInput?.lead_agents?.[agent_id]?.link)} disabled={!sendToAgentInput?.lead_agents?.[agent_id]?.link}>
                            Copy URL
                        </span>
                    </div>
                </div>

                <div className="acceptance-link">
                    <div className="link-name-url">
                        <span className="link-name">End User Link</span>
                        <span class="link-url"> {statusTrackingUrl || ""} </span>
                    </div>
                    <div className="url-btn">
                        <span className="button" onClick={_ => handleCopy(statusTrackingUrl)} disabled={!statusTrackingUrl}>
                            Copy URL
                        </span>
                    </div>
                </div>
            </>
        )));


};
export default AgentServiceTatViewAction;
