import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Loader from '../view/elements/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from '../view/dashboard/Dashboard';
import ManageRoleTab from '../view/manage-role/ManageRoleTab';
import LeadDetail from '../view/leads/LeadDetail';
import BasicDetailsForm from '../view/leads/customer-details/BasicDetails';
import SendToAgentForm from '../view/leads/send-to-agent/SendToAgent';
import NewOrcr from '../view/leads/new-orcr/NewOrcr';
import OrcrSendToDealer from '../view/leads/orcr-send-to-dealer/orcr-send-to-dealer';
import VehicleDetailsForm from '../view/leads/customer-details/VehicleDetails';
import StatusPaymentDetails from '../view/leads/StatusPaymentDetails';
import TitleTransferRequest from '../view/leads/agent/title-transfer-request';
import AgentList from '../view/agent-list/AgentList';
import LeadList from '../view/lead-list/LeadList';
import OrcrLeadList from '../view/orcr-lead-list/OrcrLeadList';
import AddApplication from '../view/leads/AddApplication';
import StatusTracking from '../view/leads/customer/status-tracking';
import UcfOrcrLeadList from '../view/ucf-orcr-lead-list/OrcrLeadList';
import Exceptional from "../view/leads/customer-details/Exceptional";
import TLApproval from '../view/leads/tl-approval/TLApproval';
import StandaloneOrcrLeadList from '../view/standalone-orcr-lead-list/OrcrLeadList';
import OcrDocUpload from '../view/standalone-orcr/ORCUpload';
// import OcrDocUpload from '../view/ocr-doc-upload/OcrDocUpload'


// Component with Lazy Loading
const Login = lazy(() => import('../view/login/Login'));
const NotFound = lazy(() => import('../view/not-found/NotFound'));
// const LeadList = lazy(() => import('../view/lead-list/LeadList'));

const MainRoute = () => {
    const [isLoading, setIsLoading] = useState(false);
    let leadDetailRoutes = (
        <>
            <Route path="/lead-detail/customer-details/basic-details/:lead_id" element={<BasicDetailsForm />} />
            <Route path="/lead-detail/sent-to-agent/:lead_id" element={<SendToAgentForm />} />
            <Route path="/lead-detail/tl-approval/:lead_id" element={<TLApproval />} />
            <Route path="/lead-detail/new-orcr/:lead_id" element={<NewOrcr setIsLoading={setIsLoading} />} />
            <Route path="/lead-detail/orcr-sent-to-dealer/:lead_id" element={<OrcrSendToDealer setIsLoading={setIsLoading} />} />
            <Route path="/lead-detail/customer-details/vehicle-details/:lead_id" element={<VehicleDetailsForm />} />
            <Route path="/lead-detail/status-details/:lead_id" element={<StatusPaymentDetails setIsLoading={setIsLoading} />} />
            <Route path="/lead-detail/payment-details/:lead_id" element={<StatusPaymentDetails setIsLoading={setIsLoading} />} />
            <Route path="/lead-detail/customer-details/exceptional/:lead_id" element={<Exceptional />} />
        </>
    );
    const [logg, setLogg] = useState(false);
    const [isValidRoute, setIsValidRoute] = useState(true);
    useEffect(() => {
        let checklog = localStorage.getItem('loggedIn');
        if (checklog) setLogg(true);
    }, []);

    const parentCallback = () => {
        let checklog = localStorage.getItem('loggedIn');
        if (checklog) setLogg(true);
    };

    // Function to determine whether to show the header
    const showHeader = () => {
        return setIsValidRoute(false);
    };

    return (
        <BrowserRouter>
            <div>
                {(logg && isValidRoute && <Header />) || null}
                {/* <Navigation /> */}
                <ToastContainer />
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route exact path="/" element={<Login parentCallback={parentCallback} />} />
                        {/* <Route path="/login-error" element={<LoginOtp/>} /> */}
                        <Route path="/login" element={<Login parentCallback={parentCallback} />} />
                        <Route
                            path="/title-transfer-request/:lead_id/:agent_id"
                            element={<TitleTransferRequest parentCallback={parentCallback} setIsLoading={setIsLoading} />}
                        />
                        <Route
                            path="/status-tracking/:lead_id"
                            element={<StatusTracking parentCallback={parentCallback} setIsLoading={setIsLoading} />}
                        />
                        <Route path="/ocr-doc-upload" element={<OcrDocUpload />} />

                        {logg && isValidRoute ? (
                            <>
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/lead-list" element={<LeadList />} />
                                <Route path="/agent-onboading" element={<AgentList />} />
                                <Route path="/lead-list/:status" element={<LeadList />} />
                                <Route path="/lead-list/:status/:searchtype/:searchtext" element={<LeadList />} />
                                <Route path="/role-list" element={<ManageRoleTab />} />
                                <Route path="/lead-detail" element={<LeadDetail />} >

                                    {leadDetailRoutes}
                                </Route>
                                <Route path="/orcr-verification/lead-list" element={<OrcrLeadList />} />
                                <Route path="/orcr-verification/lead-list/:status" element={<OrcrLeadList />} />
                                <Route path="/ucf-orcr-verification/lead-list" element={<UcfOrcrLeadList />} />
                                <Route path="/ucf-orcr-verification/lead-list/:status" element={<UcfOrcrLeadList />} />
                                <Route path="/standalone-orcr-verification/lead-list" element={<StandaloneOrcrLeadList />} />
                                <Route path="/standalone-orcr-verification/lead-list/:status" element={<StandaloneOrcrLeadList />} />
                                <Route path="/add-application" element={<AddApplication />} />
                                <Route path="/add-standalone-orcr-application" element={<OcrDocUpload />} />
                            </>
                        ) : null}
                        <Route path="*" element={<NotFound showHeader={showHeader} />} />
                    </Routes>
                </Suspense>
                {(logg && isValidRoute && <Footer />) || null}
            </div>
            {isLoading ? <Loader /> : null}
        </BrowserRouter >
    );
};

export default MainRoute;
