import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import ImageWithAlt from '../../common/ImageWithAlt';
import { MAX_VERIFICATION_LIMIT, STANDALONE_ORCR_STATUS, getFileExtensionFromURL, TITLEBOX_PATH } from '../../config/constants';
import GeneralService from '../../services/generalService';
import FormsySelect from '../elements/FormsySelect';
import FormsyTextAreaAutosize from '../elements/FormsyTextAreaAutosize';
import Loader from '../elements/Loader';
import { UPDATE_VERIFICATION_STATUS_STANDALONE_ORCR } from '../../services/standalone-orcr.gql';
import { ReasonNotVerify } from '../../config/standaloneOrcr';

export default function LeadVerification({ lead, user_id, hideVerificationDocsModel, getLeadList, getLeadsInput, paginationInput }) {
    const [verificationDocs, setVerificationDocs] = useState({ status_id: STANDALONE_ORCR_STATUS.RECIEVED, docs: [] });
    const [allowSubmit, setAllowSubmit] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const client = useApolloClient();

    const handleChangeImage = (event) => {
        let files = Array.from(event?.target?.files);
        if (MAX_VERIFICATION_LIMIT < (verificationDocs?.docs?.length + files.length)) {
            toast.error(`Max ${MAX_VERIFICATION_LIMIT} can be uploaded!`);
            return;
        }
        if (!files?.length) {
            return;
        }

        const localPreviews = files.map((file) => ({
            localUrl: URL.createObjectURL(file), // For immediate display
            file,
        }));

        var formData = new FormData();
        formData.append('upload_type', TITLEBOX_PATH);
        formData.append('lead_id', lead?.id);
        files.forEach((file, index) => {
            formData.append('images', file);
        });

        event.target.value = ''
        GeneralService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }).then((res) => {
            const docs = res?.data?.data?.map((doc, index) => {
                return { doc_path: doc?.file_url, signed_doc_path: doc?.file_url, name: files[index]?.name, displayImage: localPreviews[index]?.localUrl };
            });

            if (!verificationDocs?.docs) verificationDocs.docs = [];
            verificationDocs.docs.push(...docs);
            setVerificationDocs((prev) => ({ ...prev }));
        });
    };

    const handleChangeInput = (event) => {
        setVerificationDocs((currentValue) => ({
            ...currentValue,
            other_reject_reason: event?.target?.value
        }));
    };

    useEffect(() => {
        if (lead?.status_id) {
            const finalDoc = lead?.lead_docs?.filter((fl) => +fl.doc_id === 4);
            setVerificationDocs({
                docs: finalDoc,
                reject_reason: lead?.lead_detail?.reject_reason,
                other_reject_reason: lead?.lead_detail?.other_reject_reason,
                status_id: lead?.status_id
            });
        }
    }, [lead]);

    //Remove Docs
    const removeDoc = (name) => {

        let index = verificationDocs?.docs.findIndex(item => item.name === name);

        if (index !== -1) {
            verificationDocs.docs.splice(index, 1);
        }
        setVerificationDocs({ ...verificationDocs });
    };

    const handleSelectChange = (data, field_name) => {
        if (data && field_name) {
            if (['reject_reason'].includes(field_name)) {
                setVerificationDocs((currentValue) => ({
                    ...currentValue,
                    reject_reason: data?.value
                }));
            }

        }
    };

    const handleChange = (field_name, data) => {
        let updaetData = {};
        if (data === STANDALONE_ORCR_STATUS.VERIFIED) {
            updaetData['reject_reason'] = '';
            updaetData['status_id'] = data;
        } else {
            updaetData['reject_reason'] = '';
            updaetData['status_id'] = data;
        }
        if (lead?.status_id === STANDALONE_ORCR_STATUS.RECIEVED) {
            setVerificationDocs((currentValue) => ({
                ...currentValue,
                ...updaetData
            }));
        }
    };

    //submitVerificationDocs
    const submitVerificationDocs = async (e) => {
        if (!verificationDocs?.status_id) {
            toast.error('Please select status');
            return;
        }
        if (!verificationDocs?.docs?.length) {
            toast.error('Please upload verification doc');
            return;
        }
        const finalDoc = verificationDocs?.docs?.map((doc) => {
            return doc?.doc_path;
        });
        setIsLoading(true);
        let variables = {
            api_called_by: 'web',
            user_id: user_id || 0,
            UpdateVerificationStandaloneDocs: {
                lead_id: lead?.id,
                status_id: verificationDocs?.status_id,
                reject_reason: verificationDocs?.reject_reason,
                other_reject_reason: verificationDocs?.reject_reason === "Other" ? verificationDocs?.other_reject_reason : "",
                docs: finalDoc || []
            }
        };
        await executeGraphQLMutation(UPDATE_VERIFICATION_STATUS_STANDALONE_ORCR(), variables, client)
            .then((res) => {
                if (res?.data?.standalone_update_verification_and_docs) {
                    toast.success(res?.data?.standalone_update_verification_and_docs?.message);
                    getLeadList({
                        getLeadsInput,
                        paginationInput
                    });
                    // toggleModalBoxremark({});
                    setIsLoading(false);
                    hideVerificationDocsModel();
                } else {
                    toast.error('Error');
                    setIsLoading(false);
                }
            })
            .catch((err) => {
                toast.error(err.toString().replace('ApolloError:', ''));
                setIsLoading(false);
            });
    };

    return (
        <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={submitVerificationDocs}
            aria-label="lead form field basic"
        >
            <div className='row'>
                {isLoading ? <Loader /> : null}
                {(verificationDocs?.docs && verificationDocs?.docs?.length && verificationDocs?.docs?.map(({ name, doc_path, displayImage }) => (
                    <div className="col-md-4 p-lg-t " key={doc_path}>
                        {lead?.status_id === STANDALONE_ORCR_STATUS.RECIEVED &&
                            <div className="cross-icon" onClick={() => removeDoc(name)}></div>
                        }
                        <div className="img-bx-select-opt upload-tag-photo">
                            {['pdf', 'PDF'].includes(getFileExtensionFromURL(doc_path)) ? (
                                <a className="pdf-img " href={doc_path} target="_blank" rel="noopener noreferrer">
                                    <i className="ic-pdf-file pdf-icons-normal-gallery pdf-img-orcr"></i>
                                </a>
                            ) :
                                <ImageWithAlt src={displayImage || doc_path} alt={name} />
                            }
                        </div>
                        <div class="custom-control custom-checkbox text-center">
                            <label class="">{name}</label>
                        </div>
                    </div >
                )))
                    || null}
                {lead?.status_id === STANDALONE_ORCR_STATUS.RECIEVED && verificationDocs?.docs?.length < 9 &&
                    <div className={`${verificationDocs?.docs?.length ? 'col-md-4' : 'col-md-12'} 'p-lg-t'`} >
                        <div className="img-bx-select-opt upload-tag-photo">
                            <div className="image-bx more-file-upload">
                                <i className="ic-add"></i>
                                <form
                                    id="uploadForm"
                                    method="post"
                                    encType="multipart/form-data"
                                >
                                    <input
                                        aria-label="upload form multiple"
                                        type="file"
                                        accept="image/*, .pdf, .doc, .docx"
                                        name="fileName[]"
                                        multiple
                                        id="fileupload"
                                        onChange={(e) =>
                                            handleChangeImage(
                                                e
                                            )
                                        }
                                    />
                                </form>
                            </div>
                        </div>

                        <div class="custom-control custom-checkbox text-center">
                            <label class="">{verificationDocs?.docs?.length ? 'Add Photo' : 'Drag drop or Upload Verification Proof'} </label>
                        </div>

                    </div>
                }

                <div className="col-md-12" style={{ display: "flex", marginTop: "10px" }}>
                    <div className="input-radio">
                        <input
                            id={"radio1_"}
                            name={"status_id"}
                            type="radio"
                            checked={Number(verificationDocs?.status_id) === STANDALONE_ORCR_STATUS.VERIFIED}
                            onChange={() => handleChange(
                                "status_id",
                                STANDALONE_ORCR_STATUS.VERIFIED
                            )}
                            disabled={false}
                        />
                        <label
                            htmlFor={"radio1_"}
                            className="radio-label"
                        >
                            Verified
                        </label>
                    </div>

                    <div className="input-radio">
                        <input
                            id={"radio2_"}
                            name={"status_id"}
                            type="radio"
                            checked={Number(verificationDocs.status_id) === STANDALONE_ORCR_STATUS.NOT_VERIFIED}
                            onChange={() => handleChange(
                                "status_id",
                                STANDALONE_ORCR_STATUS.NOT_VERIFIED
                            )}
                            disabled={false}
                        />
                        <label
                            htmlFor={"radio2_"}
                            className="radio-label"
                        >
                            Not-verified
                        </label>
                    </div>
                </div>
                {verificationDocs?.status_id === STANDALONE_ORCR_STATUS.NOT_VERIFIED &&
                    <fieldset class={`single-select col-md-12 p-lg-t`}>
                        <FormsySelect
                            name={"reject_reason"}
                            id={"reject_reason"}
                            inputProps={{
                                options: ReasonNotVerify || [],
                                placeholder: "Reason for not-verified",
                                className: 'react-select',
                                classNamePrefix: 'react-select',
                                value:
                                    ReasonNotVerify?.filter(
                                        ({ value }) => value === verificationDocs?.reject_reason,
                                    ),
                                isDisabled: lead?.status_id !== STANDALONE_ORCR_STATUS.RECIEVED
                            }}
                            required={verificationDocs?.status_id === STANDALONE_ORCR_STATUS.NOT_VERIFIED}
                            showAsterisk={verificationDocs?.status_id === STANDALONE_ORCR_STATUS.NOT_VERIFIED}
                            value={""}
                            disabled={true}
                            onChange={(data) => handleSelectChange(data, 'reject_reason')}
                        />
                    </fieldset>
                }
                {verificationDocs?.reject_reason === "Other" ? <fieldset className="form-filed text-area-filed col-md-12 p-lg-t">
                    <div className="material">
                        <FormsyTextAreaAutosize
                            id={"other_reject_reason"}
                            name={"other_reject_reason"}
                            value={verificationDocs?.other_reject_reason}
                            placeholder=""
                            label={"Other Reason Remarks"}
                            onChange={handleChangeInput}
                            required={true}
                            disabled={lead?.status_id !== STANDALONE_ORCR_STATUS.RECIEVED}
                        />
                        <label data-label={"Other Reason Remarks"} className="form-label"></label>
                    </div>
                </fieldset> : null}
            </div >

            <div className="btn-save-remarks p-lg-t">
                {!allowSubmit ||
                    (!verificationDocs?.docs?.length) ||
                    lead?.status_id !== STANDALONE_ORCR_STATUS.RECIEVED ||
                    (verificationDocs?.reject_reason === 'Other' && !verificationDocs?.other_reject_reason) ||
                    (parseInt(verificationDocs?.status_id) === STANDALONE_ORCR_STATUS.RECIEVED)
                    ? (
                        <span className="span-disabled">Upload</span>
                    ) : (
                        <button aria-label="save naxt" type="submit" className="btn-primary">
                            Upload
                        </button>
                    )}
            </div>
        </Formsy>
    );
}
